import { Info, CalendarMonth, Euro, Notes, AddTask, TextSnippet, Quiz, Handshake, History, Waves } from "@mui/icons-material";

export const tabs = [
    {
        icon: <Info />,
        entity: "detail",
        label: "Detalhes",
        isDetail: true,
    },

    {
        icon: <CalendarMonth />,
        entity: "sessions",
        label: "Sessões",
    },
    {
        icon: <Waves />,
        entity: "neurofeedback_data",
        label: "Neurofeedback",
    },

    {
        icon: <Euro />,
        entity: "orders",
        label: "Compras",
    },
    {
        icon: <Notes />,
        entity: "notes",
        label: "Notas",
    },
    {
        icon: <TextSnippet />,
        entity: "feedbacks",
        label: "Feedbacks",
    },
    {
        icon: <AddTask />,
        entity: "tasks",
        label: "Tarefas",
    },
    {
        icon: <Quiz />,
        entity: "customer_surveys",
        label: "Questionários",
    },
    {
        icon: <Handshake />,
        entity: "professional_partners",
        label: "Parceiros",
    },
    {
        icon: <History />,
        entity: "timeline",
        label: "Histórico",
    },
];
