import { Box, Typography } from "@mui/material";

type NeurofeedbackGoalsProps = {
    goals: Goal[];
};

export const NeurofeedbackGoals = ({ goals }: NeurofeedbackGoalsProps) => {
    return (
        <Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="body1" color="primary">
                    Objetivos
                </Typography>
                {goals &&
                    goals.map((goal) => (
                        <Box key={goal.id} sx={{ display: "flex", gap: 1 }}>
                            <Typography variant="body1">{formatGoalType(goal?.goal_type)}</Typography>
                            <Typography variant="body1">{goal?.frequency_band?.name}</Typography>
                        </Box>
                    ))}
            </Box>
        </Box>
    );
};

export type Goal = {
    id: number;
    goal_type: string;
    frequency_band: {
        name: string;
    };
};

const formatGoalType = (goalType: string) => {
    switch (goalType) {
        case "increase":
            return "Aumentar";
        case "decrease":
            return "Diminuir";
        case "control":
            return "Controlar";
        default:
            return goalType;
    }
};
