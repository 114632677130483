import { OpenInNew } from "@mui/icons-material";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { createNFBSession, createEEGSession, getButtonText } from "../utils";
import { useStore } from "../../../store/store";
import { useRecords } from "../../../api/useRecords";
import { EEG_SERVICES, NFB_SERVICE } from "../../../utils/constants";
type NeurofeedbackDataButtonProps = {
    calendarSession: any;
};


export const NeurofeedbackDataButton = ({ calendarSession }: NeurofeedbackDataButtonProps) => {
    const navigate = useNavigate();
    const { notifications } = useStore();
    const { data: nfbSessionData, status: nfbSessionStatus } = useRecords("neurofeedback_data", "*.*", {
        extraOptions: { filter: { session: { _eq: calendarSession?.id } } },
    });

    const { data: eegData, status: eegStatus } = useRecords("evaluations", "*.*", {
        extraOptions: { filter: { eeg_session: { _eq: calendarSession?.id } } },
    });
    const calendarServiceId = calendarSession?.service?.id;

    const nfbSession = nfbSessionData?.items?.[0];
    const eegSession = eegData?.items?.[0];

    const buttonText = getButtonText(nfbSession, eegSession, calendarServiceId, NFB_SERVICE, EEG_SERVICES);

    const handleClick = async (
        nfbSession: any,
        eegSession: any,
        nfbService: number,
        eegServices: Array<number>,
        calendarServiceId: number
    ) => {
        if (nfbService === calendarServiceId) {
            if (nfbSession) {
                navigate(`/neurofeedback_data/${nfbSession?.id}/detail`);
            } else {
                createNFBSession(calendarSession, navigate, notifications);
            }
        }

        if (eegServices.includes(calendarServiceId)) {
            if (eegSession) {
                navigate(`/evaluations/${eegSession?.id}/detail`);
            } else {
                createEEGSession(calendarSession, navigate, notifications);
            }
        }
    };

    if (nfbSessionStatus === "loading" || eegStatus === "loading") {
        return <CircularProgress />;
    }
    if (nfbSessionStatus === "error" || eegStatus === "error") {
        return <Typography variant="body1">Erro ao carregar dados</Typography>;
    }

    return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
                onClick={() => handleClick(nfbSession, eegSession, NFB_SERVICE, EEG_SERVICES, calendarServiceId)}
                variant="contained"
                disableElevation
                size="large"
                color="secondary"
                endIcon={<OpenInNew />}
            >
                <Typography variant="body1">{buttonText}</Typography>
            </Button>
        </Box>
    );
};
