import { useSessionGrade } from "../../utils";
import { Box, Typography } from "@mui/material";
import { NeurofeedbackGlobalGradeChip } from "../session-grade/NeurofeedbackGlobalGradeChip";
import { extractTextAndTruncate } from "../../../../utils/extractTextAndTruncate";
type NeurofeedbackGradeGridFieldProps = {
    value: any;
};

const lineHeight = 1.2;

export const NeurofeedbackGradeGridField = ({ value }: NeurofeedbackGradeGridFieldProps) => {
    const nfbSessionGrade = value?.neurofeedback_session_grade?.[0];
    const clientCollaboration = nfbSessionGrade?.client_collaboration || 0;
    const technicianNotes = nfbSessionGrade?.technician_notes || "";
    const supervisorNotes = nfbSessionGrade?.supervisor_notes || "";
    const { sessionGlobalGrade, techAverage, supAverage } = useSessionGrade(nfbSessionGrade);

    return (
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "flex-start", gap: 4}}>
            <Box sx={{ mt: 1.5}}>
                <NeurofeedbackGlobalGradeChip grade={sessionGlobalGrade} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", width: 100 }}>
                <Typography lineHeight={lineHeight} variant="caption">
                    <b>Cliente:</b> {clientCollaboration}
                </Typography>
                <Typography lineHeight={lineHeight} variant="caption">
                    <b>Técnico:</b> {techAverage}
                </Typography>
                <Typography lineHeight={lineHeight} variant="caption">
                    <b>Supervisor:</b> {supAverage}
                </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", width: 200 }}>
                <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                    Notas do Técnico:
                </Typography>
                <Typography variant="caption">{extractTextAndTruncate(technicianNotes,30)}</Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", width: 200 }}>
                <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                    Notas do Supervisor:
                </Typography>
                <Typography variant="caption">{extractTextAndTruncate(supervisorNotes, 30)}</Typography>
            </Box>
        </Box>
    );
};
