import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { Groups } from "../groups/Groups";
import { useForm } from "react-hook-form";
import { useFields } from "../../api/useFields";
import { sanitizeData } from "../../utils/sanitizeFormData";
import { useCreateRecord } from "../../api/useCreateRecord";
import { useUpdateRecord } from "../../api/useUpdateRecord";
import { useRecord } from "../../api/useRecord";
import { GroupsOptions } from "../../types/groups.types";
import { getChangedFields } from "../../utils/getChangedFields";
import { useStore } from "../../store/store";
import { setFieldsErrors } from "../../utils/setFieldsErrors";
import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";

type AddUpdateDialogProps = {
  dialogStatus: {
    isOpen: boolean;
    type: "create" | "update";
    id: number | null;
  };
  onClose: () => void;
  entity: string;
  groupOptions?: GroupsOptions;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
};

export const AddUpdateDialog = ({
  dialogStatus,
  onClose,
  entity,
  groupOptions,
  maxWidth = "sm",
}: AddUpdateDialogProps) => {
  const entityForm = useForm({
    mode: "onChange",
    defaultValues: {},
  });
  const { formatedFields } = useFields(entity, groupOptions);
  const queryClient = useQueryClient();

  // Only fetch record if the dialog is open and it's an update operation
  const shouldFetchRecord = dialogStatus.isOpen && dialogStatus.type === "update" && dialogStatus.id !== null;

  const { data, status } = useRecord(entity, "*.*", shouldFetchRecord ? dialogStatus.id : null, {
    enabled: shouldFetchRecord,
  });


  const { notifications } = useStore();
  const createRecord = useCreateRecord();
  const updateRecord = useUpdateRecord();

  // Complete form reset whenever the dialog state changes
  useEffect(() => {
    if (dialogStatus.isOpen) {
      // When opening the dialog, reset form with empty values
      entityForm.reset({});
    } else {
      // When closing, do a complete reset of the form state
      entityForm.reset({});
    }
  }, [dialogStatus.isOpen, dialogStatus.id, dialogStatus.type, entity, entityForm, queryClient]);

  // Handle dialog close - resets form and calls onClose
  const handleClose = () => {
    entityForm.reset({});
    onClose();
  };

  const onSubmit = async (formData: any) => {
    const dataToSubmit = getChangedFields({
      formData,
      formState: entityForm.formState,
      originalData: data?.item || {},
      isUpdate: dialogStatus.type === "update",
    });

    const sanitizedData = sanitizeData(dataToSubmit, formatedFields);

    if (dialogStatus.type === "update" && dialogStatus.id) {
      try {
        await updateRecord.mutateAsync({
          entity: entity,
          data: sanitizedData,
          id: dialogStatus.id,
        });

        notifications.setNotification({
          isOpen: true,
          message: `Registo atualizado com sucesso`,
          severity: "success",
        });

        entityForm.reset({});
        onClose();
      } catch (error: any) {
        if (error?.response?.data?.errors) {
          // Set form errors - no need for notification since errors are displayed in the form
          setFieldsErrors(error.response.data.errors, entityForm);

          notifications.setNotification({
            isOpen: true,
            message: `Erro ao atualizar registo`,
            severity: "error",
          });
        }
      }
    } else {
      try {
        await createRecord.mutateAsync({
          entity: entity,
          data: sanitizedData,
        });

        notifications.setNotification({
          isOpen: true,
          message: `Registo criado com sucesso`,
          severity: "success",
        });

        entityForm.reset({});
        onClose();
      } catch (error: any) {
        if (error?.response?.data?.errors) {
          // Set form errors - no need for notification since errors are displayed in the form
          setFieldsErrors(error.response.data.errors, entityForm);
          notifications.setNotification({
            isOpen: true,
            message: `Erro ao criar registo`,
            severity: "error",
          });
        }
      }
    }
  };

  if (shouldFetchRecord && status === "loading") return <CircularProgress />;
  if (shouldFetchRecord && status === "error") return <Typography>Erro ao carregar dados</Typography>;

  return (
    <Dialog open={dialogStatus.isOpen} onClose={handleClose} maxWidth={maxWidth} fullWidth>
      <form onSubmit={entityForm.handleSubmit(onSubmit)}>
        <DialogContent sx={{ width: "100%" }}>
          <Groups
            item={shouldFetchRecord ? data?.item : undefined}
            fields={formatedFields}
            entityForm={entityForm}
            type={dialogStatus.type}
            groupsOptions={groupOptions}
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button type="submit" disabled={createRecord.isLoading}>
            Gravar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
