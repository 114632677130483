import { api } from "../../api/config";
import { RecordContextType } from "../../components/record/Record";
import { router } from "../../browser-router/router";
import { getStore } from "../../store/store";

export const actions = [
    {
        code: "convert_client",
        name: "Converter Para Cliente",
        actionFn: (context: any, executeAction: any) => convertToClient(context),
    },
    {
        code: "send_sms",
        name: "Enviar SMS",
        actionFn: (context: any, executeAction: any) => openSmsDialog(context),
    },
];

const convertToClient = async (context: RecordContextType) => {
    const confirmed = window.confirm(
        'Tem a certeza que a informação em baixo está correta?\n- Nome\n- Email\n- Data de nascimento.\n Após pressionar OK esses dados serão utilizados para criar o ID do cliente e enviar email com questionários'
    );
    if (!confirmed) return;

    try {
        const response = await api({
            method: "POST",
            url: "https://hook.eu1.make.com/nxhuuudduop4f8g34xjta0il2mob5nqj",
            data: context.item,
        });

        const data = response.data;

        router.navigate(`/clients/${data.client_id}/update`);
    } catch (error: any) {
        throw new Error(error.response.data);
    }
};

const openSmsDialog = (context: RecordContextType) => {
    try {
        const { sms } = getStore();
        sms.setIsOpen(true);
        sms.setContext(context);

        return {
            showSuccessMessage: "no",
        };
    } catch (error: any) {
        throw new Error("algo correu mal");
    }
};
