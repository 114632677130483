import { Box, Typography } from "@mui/material";
import { extractArrayFromManyToMany } from "../../utils";

export type ProtocolFactor = {
    factors_id: {
        id: number;
        name: string;
    };
};

type FactorsProps = {
    factors: ProtocolFactor[];
};

export const Factors = ({ factors }: FactorsProps) => {
    const extractedProtocolFactors = extractArrayFromManyToMany(factors, "factors");

    return (
        <Box>
            {extractedProtocolFactors.map((factor) => (
                <Typography key={factor.id} variant="body1">
                    {factor.name}
                </Typography>
            ))}
        </Box>
    );
};
