import { Avatar as AvatarMui, SxProps } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { useImage } from "../../../api/useImage";

type AvatarProps = {
    value?: string;
    styles?: SxProps;
};

export function Avatar({ value, styles }: AvatarProps) {
    const { data } = useImage(value);

    if (!data) return <AccountCircle fontSize="large" color="disabled" />;

    return <AvatarMui sx={styles} src={data} />;
}
