import { Box, Button, CircularProgress, IconButton, Typography } from "@mui/material";
import { useFileUploadToField } from "../../../api/useFileUploadToField";
import { useContext, useEffect } from "react";
import { RecordContext } from "../../record/Record";
import { useFilesData } from "../../../api/useFilesData";
import { Unstable_Grid2 as Grid } from "@mui/material";
import { Delete, OpenInNew, TextSnippetRounded } from "@mui/icons-material";
import { useCreateFileUrl } from "../../../api/useCreateFileUrl";
import { Link } from "react-router-dom";
import { FieldExtraOption, FieldView, FormatedField } from "../../../types/fields.types";
import { useDeleteFile } from "../../../api/useDeleteFile";
import { useStore } from "../../../store/store";

type FileFieldProps = {
    entity?: string;
    id?: string | number;
    value: Array<{
        id: string | number;
        directus_files_id: string;
        [key: string]: any;
    }>;
    field: FormatedField;
    fieldView: FieldView;
    extraOptions: FieldExtraOption;
};

export const FileField = ({ entity, id, value, field, fieldView, extraOptions }: FileFieldProps) => {
    const context = useContext(RecordContext);
    const deleteFile = useDeleteFile();
    const { refetchFiles } = useStore();

    const entityToUse = entity || context?.entity;
    const idToUse = id || context?.id;

    const { mutate, isLoading, isError } = useFileUploadToField(field);
    const fieldId = fieldView === "group" ? idToUse : value?.[0]?.[`${field?.settings?.entity}_id`];

    const { data, status, refetch } = useFilesData({
        entity: entityToUse,
        id: fieldId,
        field: field.field,
        directus_files: value,
    });

    const createFileUrl = useCreateFileUrl();

    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = event.target.files;

        if (!entityToUse || !idToUse) return;

        mutate({
            file: fileList![0],
            entity: entityToUse,
            itemId: idToUse,
            folder: extraOptions.fileFolder,
        });
    };

    useEffect(() => {
        if (refetchFiles.timestamp && refetchFiles.timestamp > 0) {
            const timeoutId = setTimeout(() => {
                refetch();
            }, 1000);

            return () => clearTimeout(timeoutId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetchFiles.timestamp]);

    if (status === "loading") return <CircularProgress />;
    if (status === "error") return <Typography color="error">Erro ao carregar ficheiros</Typography>;

    if (fieldView === "grid") {
        return (
            <Box>
                {data?.map((file) => {
                    return (
                        <IconButton
                            key={file.id}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            component={Link}
                            to={createFileUrl(file.id)}
                            target="_blank"
                            size="medium"
                            color="primary"
                        >
                            <TextSnippetRounded />
                        </IconButton>
                    );
                })}
            </Box>
        );
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", ...extraOptions?.style?.fileList }}>
            {extraOptions?.hidden?.fileList !== true &&
                data?.map((file) => {
                    return (
                        <Grid container key={file.id} alignItems="center">
                            <Grid display="flex" alignItems="center">
                                <Button
                                    component={Link}
                                    variant="text"
                                    to={createFileUrl(file.id)}
                                    target="_blank"
                                    color="secondary"
                                >
                                    {fileTitle(file.title)}

                                    <input type="file" hidden onChange={handleFileSelect} />
                                </Button>
                                <IconButton
                                    component={Link}
                                    to={createFileUrl(file.id)}
                                    target="_blank"
                                    size="small"
                                    color="secondary"
                                >
                                    <OpenInNew />
                                </IconButton>
                                <IconButton
                                    onClick={() => {
                                        entityToUse && deleteFile.mutate({ id: file.id, entity: entityToUse });
                                    }}
                                    size="small"
                                    color="primary"
                                >
                                    <Delete />
                                </IconButton>
                            </Grid>
                        </Grid>
                    );
                })}

            {isLoading ? (
                <CircularProgress />
            ) : isError ? (
                <Typography>Não foi possível fazer upload</Typography>
            ) : extraOptions?.hidden?.detail !== true ? (
                <>
                    {data.length > 0 && (
                        <Button
                            disabled={extraOptions?.disabled}
                            component="label"
                            size="small"
                            variant={extraOptions?.style?.uploadButton?.variant || "outlined"}
                            sx={{ color: extraOptions?.style?.uploadButton?.color || "grey" }}
                        >
                            Carregar Outro Ficheiro
                            <input type="file" hidden onChange={handleFileSelect} />
                        </Button>
                    )}
                    {data.length === 0 && (
                        <Button disabled={extraOptions?.disabled} component="label">
                            Carregar Ficheiro
                            <input type="file" hidden onChange={handleFileSelect} />
                        </Button>
                    )}
                </>
            ) : null}
        </Box>
    );
};

const fileTitle = (fileTitle: string) => {
    const maxFileTitleLength = 20;
    if (fileTitle.length < maxFileTitleLength) {
        return fileTitle;
    }

    return fileTitle.substring(0, 20) + "...";
};
