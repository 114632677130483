export const neuroFeebackDataFilter = (parentId: string) => {
    return {
        protocol: {
            treatment: {
                id: {
                    _eq: parentId,
                },
            },
        },
    };
};

export const protocolFilter = (parentId: string) => {
    return {
        treatment: {
            id: {
                _eq: parentId,
            },
        },
    };
};

export const evaluationFilter = (parentId: string) => {
    return {
        treatment: {
            id: {
                _eq: parentId,
            },
        },
    };
};



export const createRelatedParams = (entity: string, treatmentId: string) => {
    switch (entity) {
        case "evaluations":
            return `[treatments][id][*,client.*,client.user_id.*]`;
        case "protocols":
            return `[treatments][id]=${treatmentId}`;
        case "neurofeedback_data":
            return `[treatments][id]=${treatmentId}`;
    }
};
