import { FormState } from "react-hook-form";

type GetChangedFieldsParams = {
  formData: Record<string, any>;
  formState: FormState<any>;
  originalData?: Record<string, any>;
  isUpdate: boolean;
};

export const getChangedFields = ({ 
  formData, 
  formState, 
  originalData = {}, 
  isUpdate 
}: GetChangedFieldsParams): Record<string, any> => {
  const touchedFields = Object.keys(formState.touchedFields);
  
  // First, collect all non-empty fields AND all touched fields (even if empty)
  const relevantFields = Object.entries(formData).reduce((acc, [key, value]) => {
    // Include if non-empty OR if it was explicitly touched
    if (value !== "" || touchedFields.includes(key)) {
      acc[key] = value;
    }
    return acc;
  }, {} as Record<string, any>);
  
  // For new records, return all relevant fields
  if (!isUpdate) {
    return relevantFields;
  }
  
  // For updates, filter to only fields that changed compared to original
  const changedFieldKeys = Object.keys(relevantFields).filter(key => {
    return JSON.stringify(relevantFields[key]) !== JSON.stringify(originalData[key]);
  });
  
  return Object.fromEntries(
    changedFieldKeys.map(key => [key, relevantFields[key]])
  );
}; 