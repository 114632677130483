import { Box, Typography, Checkbox } from "@mui/material";
import { NFBsessionGradeValues } from "../../types";
import { useUpdateRecord } from "../../../../api/useUpdateRecord";
import { useStore } from "../../../../store/store";
import { ChangeEvent } from "react";

type NeurofeedbackSupervisorNotesGradeProps = {
    notes: string;
    nfbSessionGrade: NFBsessionGradeValues;
};

export const NeurofeedbackSupervisorNotesGrade = ({ notes, nfbSessionGrade }: NeurofeedbackSupervisorNotesGradeProps) => {
    const updateRecord = useUpdateRecord();
    const { notifications } = useStore();

    const handleCheck = async (event: ChangeEvent<HTMLInputElement>, type: "technician_notified" | "technician_read") => {
        const isConfirmed = window.confirm("Tem a certeza?");

        if (!isConfirmed) {
            return;
        }

        try {
            await updateRecord.mutateAsync({
                id: nfbSessionGrade.id,
                entity: "nfb_session_grades",
                data: {
                    [type]: event.target.checked,
                },
            });

            notifications.setNotification({
                message: "Atualizado com sucesso",
                severity: "success",
                isOpen: true,
            });
        } catch (error: any) {
            notifications.setNotification({
                message: error.response.data,
                severity: "error",
                isOpen: true,
            });
        }
    };

    return (
        <Box sx={{ display: "flex", gap: 2 }}>
            <Box sx={{ width: "100%" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 4 }}>
                    <Box>
                        <Typography variant="h6" color="primary">
                            Feedback Supervisor
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
                        <CheckboxWithLabel
                            checked={(nfbSessionGrade.technician_read as unknown as boolean) || false}
                            onChange={(e) => handleCheck(e, "technician_read")}
                            label="Lido por técnico"
                        />
                        <CheckboxWithLabel
                            checked={(nfbSessionGrade.technician_notified as unknown as boolean) || false}
                            onChange={(e) => handleCheck(e, "technician_notified")}
                            label="Notificar Técnico"
                        />
                    </Box>
                </Box>
                <Typography variant="subtitle2">{notes}</Typography>
            </Box>
        </Box>
    );
};

type CheckboxWithLabelProps = {
    checked: boolean;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    label: string;
};

const CheckboxWithLabel = ({ checked, onChange, label }: CheckboxWithLabelProps) => {
    return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Checkbox checked={checked} onChange={onChange} />
            <Typography variant="subtitle2">{label}</Typography>
        </Box>
    );
};
