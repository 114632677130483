import { useQuery } from "@tanstack/react-query";
import { api, baseUrl } from "./config";
import store2 from "store2";

// Function to get authenticated URL for an image
export const getAuthenticatedImageUrl = (id?: string, isOriginal?: boolean) => {
    if (!id) return "";
    
    const tokens = store2.get("auth");
    if (!tokens) return "";
    
    const transformation = isOriginal ? "fit" : "key=thumbnail";
    return `${baseUrl}/assets/${id}?${transformation}&access_token=${tokens.access_token}`;
};

export const useImage = (id?: string, isOriginal?: boolean) => {
    const queryKey = [`image-${id}`, isOriginal];
    
    return useQuery({
        queryKey: queryKey,
        queryFn: () => getAuthenticatedImageUrl(id, isOriginal),
        enabled: !!id,
    });
};

export const useImages = (ids?: Array<string>, isOriginal?: boolean) => {
    const queryKey = [`images`, ids, isOriginal];
    
    return useQuery({
        queryKey: queryKey,
        queryFn: () => getImages(ids, isOriginal),
        enabled: !!ids?.length,
    });
};

export const getImages = async (ids?: Array<string>, isOriginal?: boolean) => {
    if (!ids || !ids.length) return [];
    
    return ids.map(id => getAuthenticatedImageUrl(id, isOriginal));
};

export const useGalleryImages = (ids?: Array<string>) => {
    const queryKey = [`gallery-images`, ids];
    
    return useQuery({
        queryKey: queryKey,
        queryFn: () => getGalleryImages(ids),
        enabled: !!ids?.length,
    });
};

export const getGalleryImages = async (ids?: Array<string>) => {
    if (!ids || !ids.length) return [];

    const images = [];
    for (const id of ids) {
        const original = getAuthenticatedImageUrl(id, true);
        const thumbnail = getAuthenticatedImageUrl(id, false);
        images.push({
            original: original,
            thumbnail: thumbnail,
            originalHeight: 600,
        });
    }
    return images;
};