import { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    IconButton,
    Tooltip,
    LinearProgress,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
} from "@mui/material";
import { DateFilter } from "./components/DateFilter";

import { Avatar } from "../../components/fieldsViews/fields/Avatar";
import { useRecords } from "../../api/useRecords";
import { FileField } from "../../components/fieldsViews/fields/FileField";
import { useField } from "../../api/useField";
import { SalariesUploadButton } from "./components/SalariesUploadButton";
import { ExportExcel } from "../../components/export/ExportExcel";
import {  DoneAll, OpenInNew, Error } from "@mui/icons-material";
import { Announcement } from "@mui/icons-material";
import { SPECIAL_USERS } from "../../utils/constants";
import { endOfMonth, startOfMonth } from "date-fns";
import { calculateTotals, calculateTotalValueServiceProvider, getServiceProviderPriceForDate } from "./utils/calculations";
import { getExportData } from "./utils/exportData";
import { useStaffWorkLogsFilter, filterEmployeeSummaries } from "./utils/filters";
import { useWorkLogs } from "./utils/useWorkLogs";
import { sortBy } from "lodash";

export const StaffWorkLogsList = () => {
    const { formatedField: documentField } = useField("staff_monthly_payments", "files");
    const [selectedAgreementType, setSelectedAgreementType] = useState<string>("all");
    const { selectedYear, setSelectedYear, selectedMonth, setSelectedMonth } = useStaffWorkLogsFilter();

    const startDate = startOfMonth(new Date(selectedYear, selectedMonth));
    const endDate = endOfMonth(new Date(selectedYear, selectedMonth));

    const { workLogs, timeOff, isLoading, isError, isCurrentUserAdmin, currentUser } = useWorkLogs(startDate, endDate);

    const { data: payments } = useRecords("staff_monthly_payments", "*.*", {
        extraOptions: {
            filter: { _and: [{ year: { _eq: selectedYear } }, { month: { _eq: selectedMonth + 1 } }] },
        },
    });

    const employeeSummaries = useMemo(() => {
        if (isLoading || isError) return [];

        const summariesMap = new Map();

        workLogs.forEach((log: any) => {
            if (log.user && log.user.id) {
                if (!summariesMap.has(log.user.id)) {
                    summariesMap.set(log.user.id, {
                        user: log.user,
                        workLogs: [],
                        timeOffs: [],
                    });
                }
                summariesMap.get(log.user.id).workLogs.push(log);
            }
        });

        timeOff.forEach((to: any) => {
            if (!summariesMap.has(to.user.id)) {
                summariesMap.set(to.user.id, {
                    user: to.user,
                    workLogs: [],
                    timeOffs: [],
                });
            }
            summariesMap.get(to.user.id).timeOffs.push(to);
        });
        payments?.items?.forEach((payment: any) => {
            if (summariesMap.has(payment.user.id)) {
                const summary = summariesMap.get(payment.user.id);
                summary.payment = {
                    ...payment,
                };
            }
        });

        return Array.from(summariesMap.values()).map((summary) => {
            return {
                user: summary.user,
                ...calculateTotals(summary.workLogs, summary.timeOffs),
                payment: summary.payment || null,
            };
        });
    }, [workLogs, timeOff, payments, isLoading, isError]);

    const filteredEmployeeSummaries = useMemo(() => {
        // Sort summaries by first name (ascending) before filtering
        const sortedEmployeeSummaries = sortBy(employeeSummaries, (summary) => summary.user.first_name);

        return filterEmployeeSummaries(sortedEmployeeSummaries, selectedAgreementType);
    }, [employeeSummaries, selectedAgreementType]);

    if (isLoading) {
        return <LinearProgress color="secondary" />;
    }

    if (!isCurrentUserAdmin && SPECIAL_USERS.joana_director !== currentUser?.id) {
        return <Typography variant="h6">Você não tem permissão para ver esta página</Typography>;
    }

    if (isError) {
        return <Typography>Error loading data</Typography>;
    }

    return (
        <Box>
            <Paper sx={{ p: 2, mb: 2, display: "flex", justifyContent: "space-between", gap: 4, alignItems: "center" }}>
                <DateFilter
                    selectedYear={selectedYear}
                    selectedMonth={selectedMonth}
                    setSelectedYear={setSelectedYear}
                    setSelectedMonth={setSelectedMonth}
                />
                <FormControl fullWidth>
                    <InputLabel id="agreement-type-select-label">Tipo de Acordo</InputLabel>
                    <Select
                        labelId="agreement-type-select-label"
                        value={selectedAgreementType}
                        onChange={(e) => setSelectedAgreementType(e.target.value as string)}
                        fullWidth
                        label="Tipo de Acordo"
                    >
                        <MenuItem value="all">Todos</MenuItem>
                        <MenuItem value="services_provider">Recibos Verdes</MenuItem>
                        <MenuItem value="contracts">Contratos</MenuItem>
                    </Select>
                </FormControl>
                {isCurrentUserAdmin && (
                    <>
                        <SalariesUploadButton
                            selectedYear={selectedYear}
                            selectedMonth={selectedMonth}
                            type="salaries"
                            buttonText="Enviar salários"
                        />

                        <ExportExcel
                            getData={() => getExportData(employeeSummaries, "salaries")}
                            fileName={`salaries_${selectedYear}_${selectedMonth + 1}`}
                            buttonText="Exportar Banco"
                            includeHeaders={false}
                            format="txt"
                        />
                        <ExportExcel
                            getData={() => getExportData(employeeSummaries, "subsidies")}
                            fileName={`subsidy_${selectedYear}_${selectedMonth + 1}`}
                            buttonText="Exportar Ticket"
                            includeHeaders={true}
                        />
                    </>
                )}
            </Paper>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Detalhe</TableCell>
                            <TableCell align="left">Colaborador</TableCell>
                            <TableCell align="center">Horas Trabalhadas</TableCell>
                            <TableCell align="center">Horas Clientes</TableCell>
                            <TableCell align="center">Férias</TableCell>
                            <TableCell align="center">Baixa</TableCell>
                            <TableCell align="center">Validação</TableCell>
                            <TableCell align="center">Notas</TableCell>
                            {documentField && <TableCell align="center">Documentos</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredEmployeeSummaries.map((summary: any) => {
                            // Get the date for the payment month
                            const paymentDate = new Date(summary.payment?.year, summary.payment?.month - 1);
                            
                            // Get the correct price values based on the date
                            const { valuePerHour, valuePerClient } = getServiceProviderPriceForDate(
                                summary.payment?.staff_agreement?.service_provider_prices,
                                paymentDate
                            );
                            
                            const totalValue = calculateTotalValueServiceProvider({
                                effectiveWorkHours: summary.effectiveWorkHours,
                                valuePerHour: valuePerHour,
                                totalClientHours: summary.totalClientHours,
                                valuePerClient: valuePerClient,
                            });

                            const isServiceProvider = summary.payment?.staff_agreement?.agreement_type === "services_provider";
                            const paymentGross = summary?.payment?.gross;
                            const hasDocuments = summary?.payment?.files?.length > 0;

                            return (
                                <TableRow key={summary.user.id}>
                                    <TableCell align="center">
                                        <IconButton
                                            sx={{ p: 0 }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                            color="secondary"
                                            component={Link}
                                            to={`/staff-work-logs/${summary.user.id}?year=${selectedYear}&month=${
                                                selectedMonth + 1
                                            }`}
                                            target="_blank"
                                        >
                                            <OpenInNew />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Box
                                            component={Link}
                                            to={`/staff-work-logs/${summary.user.id}`}
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: 4,
                                                textDecoration: "none",
                                                color: "inherit",
                                            }}
                                        >
                                            <Avatar value={summary.user.avatar} />
                                            <Typography>{`${summary.user.first_name} ${summary.user.last_name}`}</Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell align="center">{summary.totalWorkHours.toFixed(2)}</TableCell>
                                    <TableCell align="center">{summary.totalClientHours.toFixed(2)}</TableCell>
                                    <TableCell align="center">{summary.totalHolidays}</TableCell>
                                    <TableCell align="center">{summary.totalSickLeaveDays}</TableCell>
                                    <TableCell align="center">
                                        {summary.payment?.admin_validated ? <DoneAll color="success" /> : null}

                                        {isServiceProvider && paymentGross && totalValue !== paymentGross ? (
                                            <Error sx={{ ml: 1 }} color="error" />
                                        ) : null}
                                    </TableCell>
                                    <TableCell align="center">
                                        {summary.payment?.note && (
                                            <Tooltip title={summary.payment.note} placement="left" arrow>
                                                <IconButton color="warning">
                                                    <Announcement />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </TableCell>

                                    <TableCell align="center">
                                        {isCurrentUserAdmin && documentField && hasDocuments && summary.payment?.id && (
                                            <FileField
                                                value={summary.payment?.files}
                                                field={documentField}
                                                fieldView="grid"
                                                entity="staff_monthly_payments"
                                                id={summary.payment?.id}
                                                extraOptions={{
                                                    fileFolder: "da5abe27-4c83-40fd-abac-47f5e8af2677",
                                                    field: "files",
                                                }}
                                            />
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};
