import { Box, Toolbar } from "@mui/material";
import { AppBar } from "../appbar/AppBar";
import { Menu } from "../menu/Menu";
import { Outlet } from "react-router-dom";
import { AlertDialog } from "../alerts/AlertDialog";
import { SnackBar } from "../alerts/SnackBar";
import { useWebSockets } from "../../api/useWebSockets";
import { useUpdateTokens } from "../../utils/useUpdateTokens";
import { SMSDialog } from "../sms-dialog/SMSDialog";
import { EvaluationReportDialog } from "../evaluation-report-dialog/EvaluationReportDialog";
import { useContext } from "react";
import { CollectionsContext } from "./CollectionsProvider";
import { PaymentDialog } from "../payment-dialog/PaymentDialog";
import { ProcessesSearchDialog } from "../../screens-processes/components/ProcessesSearchDialog";
import { OrdersDialog } from "../orders-dialog/OrdersDialog";
import { TreatmentAndPaymentInformation } from "../treatment-and-payment-information/TreatmentAndPaymentInformation";

export const AppContent = () => {
    useUpdateTokens();
    useWebSockets();
    const collections = useContext(CollectionsContext);


    return (
        <Box>
            <AppBar />
            <Toolbar />
            <Box display="flex">
                <Menu />
                <Box
                    component="main"
                    sx={{
                        height: "calc(100vh - 64px)",
                        display: "flex",
                        flexDirection: "column",
                        p: 2,
                        flexGrow: 1,
                    }}
                >
                    <Outlet />
                </Box>
            </Box>
            <AlertDialog />
            <SnackBar />
            <SMSDialog />
            <PaymentDialog />
            <TreatmentAndPaymentInformation />
            <OrdersDialog />
            <ProcessesSearchDialog />
            {collections?.collections.find((collection) => collection.collection === "evaluations") && <EvaluationReportDialog />}
        </Box>
    );
};
