import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { useRecords } from "../../../../api/useRecords";

type NeurofeedbackChangeSessionProps = {
    nfbZScoreSession: any;
    previousSessionNumber: number;
    nextSessionNumber: number;
};

export const NeurofeedbackChangeSession = ({
    nfbZScoreSession,
    previousSessionNumber,
    nextSessionNumber,
}: NeurofeedbackChangeSessionProps) => {
    const treatmentId = nfbZScoreSession?.item?.protocol?.treatment;

    const filter = {
        _and: [
            {
                protocol: {
                    treatment: {
                        id: {
                            _eq: treatmentId,
                        },
                    },
                },
                _or: [
                    {
                        session_number: {
                            _eq: previousSessionNumber,
                        },
                    },
                    {
                        session_number: {
                            _eq: nextSessionNumber,
                        },
                    },
                ],
            },
        ],
    };

    const { data } = useRecords("neurofeedback_data", "*.*", {
        extraOptions: {
            filter: filter,
        },
    });

    const previousAndNextSessions = data?.items;
    const previousSession = previousAndNextSessions?.find((session: any) => session.session_number === previousSessionNumber);
    const nextSession = previousAndNextSessions?.find((session: any) => session.session_number === nextSessionNumber);

    return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <IconButton
                color="primary"
                component={Link}
                to={`/neurofeedback_data/${previousSession?.id}/detail`}
                disabled={!previousSession}
            >
                <ArrowBackIos />
            </IconButton>
            <IconButton
                color="primary"
                component={Link}
                to={`/neurofeedback_data/${nextSession?.id}/detail`}
                disabled={!nextSession}
            >
                <ArrowForwardIos />
            </IconButton>
        </Box>
    );
};
