import { Box, Paper, Typography } from "@mui/material";
import { Goal, NeurofeedbackGoals } from "./NeurofeedbackGoals";
import { ElectrodeLocations, ElectrodesLocation } from "./ElectrodeLocations";
import { Factors, ProtocolFactor } from "./Factors";
import { FrequencyBand, FrequencyBands } from "./FrequencyBands";

type NeurofeedbackProtocolProps = {
    protocol: {
        neurofeedback_goal: Goal[];
        electrodes_locations: ElectrodesLocation[];
        pzok: number;
        factors_out: number;
        notes: string;
        factors: ProtocolFactor[];
        frequency_bands: FrequencyBand[];
    };
};

export const NeurofeedbackProtocol = ({ protocol }: NeurofeedbackProtocolProps) => {
    const goals = protocol?.neurofeedback_goal;
    const electrodesLocations = protocol?.electrodes_locations;
    const pzok = protocol?.pzok;
    const factorsOut = protocol?.factors_out;
    const protcolFactors = protocol?.factors;
    const protocolNotes = protocol?.notes;
    const frequencyBands = protocol?.frequency_bands;

    return (
        <Paper sx={{ display: "flex", flexDirection: "column", gap: 2, padding: 2 }}>
            <Box sx={{ display: "flex", gap: 6, justifyContent: "space-between" }}>
                <NeurofeedbackGoals goals={goals} />
                <Box>
                    <Typography variant="body1" color="primary">
                        Localizações
                    </Typography>
                    <ElectrodeLocations electrodesLocations={electrodesLocations} />
                    <Box>
                        <Typography variant="body1" color="primary">
                            Factores
                        </Typography>
                        <Factors factors={protcolFactors} />
                    </Box>
                </Box>
                <Box>
                    <Typography variant="body1" color="primary">
                        Ondas Cerebrais
                    </Typography>
                    <FrequencyBands frequencyBands={frequencyBands} />
                </Box>
                <Box>
                    <Typography variant="body1" color="primary">
                        Pzok
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="body1">Número: {pzok}</Typography>
                        <Typography variant="body1">Factores fora: {factorsOut}</Typography>
                    </Box>
                </Box>
                <Box sx={{ maxWidth: 300 }}>
                    <Typography variant="body1" color="primary">
                        Nota Permanente
                    </Typography>
                    <Typography variant="subtitle2">{protocolNotes}</Typography>
                </Box>
            </Box>
        </Paper>
    );
};
