import { getItems } from "./items";
import { useQuery } from "@tanstack/react-query";
import { DataGridOptions } from "../components/datagrid/DataGrid";
import { FieldsToRemoveType } from "../utils/formatFields";
import { RecordContextType } from "../components/record/Record";
import { GroupsOptions } from "../types/groups.types";
import { useContext } from "react";
import { CollectionsContext } from "../components/root/CollectionsProvider";

export type UseRecordsOptions = {
    fieldsToRemove?: FieldsToRemoveType;
    queryKeyExtraValue?: string;
    dataGridOptions?: DataGridOptions;
    groupsOptions?: GroupsOptions;
    fetch?: {
        onDemand?: boolean;
        isOpen?: boolean;
        fetchOnFocus?: boolean;
        fetchInterval?: number;
        refetchIntervalInBackground?: boolean;
    };
    enabled?: boolean;
    fieldsWithExtraOptions?: Array<any>;
    extraOptions?: {
        filter?: any;
        page?: number;
        pageSize?: number;
        sort?: string;
    };
    createRelatedParams?: (params: RecordContextType | undefined) => Promise<string | undefined>;
    createFilter?: (params: any) => any;
    relatedRecords?: {
        type?: "many_to_many";
        association?: boolean;
        hideCreationButton?: boolean;
    };
    staleTime?: number | typeof Infinity;
};

export const useRecords = (entity: string, fieldsQuery?: string, options?: UseRecordsOptions) => {
    const extraQueryValue = generateQueryValue(options);
    const collections = useContext(CollectionsContext);

    return useQuery({
        queryKey: [`${entity}-records${extraQueryValue}`],
        queryFn: () => {
            const emptyObject = {
                items: [],
                metadata: { total_count: 0 },
                fields: [],
                collection: {},
                entity,
            };

            const hasAccessToEntity = collections?.collections.find((collection) => collection.collection === entity);
            if (!hasAccessToEntity) return emptyObject;

            if (!options?.fetch?.onDemand) {
                return getRecords(entity, fieldsQuery, options);
            } else if (options?.fetch.onDemand && options?.fetch.isOpen) {
                return getRecords(entity, fieldsQuery, options);
            } else {
                return emptyObject;
            }
        },
        refetchInterval: options?.fetch?.fetchInterval,
        refetchIntervalInBackground: options?.fetch?.refetchIntervalInBackground,
        enabled: options?.enabled,
        staleTime: options?.staleTime,
    });
};

export const getRecords = async (entity: string, fieldsQuery?: string, options?: UseRecordsOptions) => {
    const { items, metadata } = await getItems(entity, fieldsQuery, options?.extraOptions);

    return { items, metadata, entity };
};

export const generateQueryValue = (options: UseRecordsOptions | undefined) => {
    if (options?.queryKeyExtraValue) return `-${options?.queryKeyExtraValue}`;
    if (options?.extraOptions) {
        return `-${JSON.stringify(options?.extraOptions)}`;
    }

    return "";
};
