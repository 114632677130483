import { Modal, Paper, Grid, Select, MenuItem, Button, FormControl, InputLabel } from "@mui/material";
import { useCreateRecord } from "../../../api/useCreateRecord";
import { useUpdateRecord } from "../../../api/useUpdateRecord";
import { renderFieldInput } from "../../../components/fieldsInputs/renderFieldInput";
import { useFields } from "../../../api/useFields";
import { findField } from "../../../utils/findField";
import { useContext } from "react";
import { StaffWorkLogsContext } from "../StaffWorkLogsDetail";
import { Controller } from "react-hook-form";
import { useStore } from "../../../store/store";
import { ClinicsDropdown } from "../../../components/general-dropdowns/clinics-dropdown/ClinicsDropdown";
import { ROLES } from "../../../utils/constants";

export const WorkLogModal = () => {
    const { workLogForm, setIsModalOpen, isModalOpen, isCurrentUserAdmin, currentUser } = useContext(StaffWorkLogsContext);

    const { notifications } = useStore();
    const createRecord = useCreateRecord();
    const updateRecord = useUpdateRecord();

    const { formatedFields, status: fieldsStatus } = useFields("schedules");
    const startDateField = findField(formatedFields, "start_date");
    const endDateField = findField(formatedFields, "end_date");
    const noteField = findField(formatedFields, "notes");
    const timeOffType = workLogForm.watch("type");
    const disableDateFields = timeOffType === "holiday" || timeOffType === "sick_leave";

    const managerRoles = [ROLES.neurofeedback_director, ROLES.reception_director, ROLES.technical_director];

    const isCurrentUserAdminOrManager = currentUser && (isCurrentUserAdmin || managerRoles.includes(currentUser?.role.id));

    const handleSave = async (data: any) => {
        const entity = data.type === "schedules" ? "schedules" : "time_off";

        try {
            if (data.id) {
                await updateRecord.mutateAsync({
                    entity,
                    id: data.id,
                    data: {
                        start_date: data.start_date,
                        end_date: data.end_date,
                        user: data.user,
                        notes: data.notes,
                        clinic_id: 1,
                    },
                });
            } else {
                await createRecord.mutateAsync({
                    entity,
                    data: {
                        start_date: data.start_date,
                        end_date: data.end_date,
                        user: data.user,
                        notes: data.notes,
                        type: entity === "time_off" ? data.type : undefined,
                        clinic_id: 1,
                    },
                });
            }
            workLogForm.reset();
            notifications.setNotification({
                isOpen: true,
                message: "Registo guardado com sucesso",
                severity: "success",
            });
            setIsModalOpen(false);
        } catch (error: any) {
            const errorMessage = error.response.data.errors[0].message;
            notifications.setNotification({
                isOpen: true,
                message: errorMessage,
                severity: "error",
            });
        }
    };

    const types = isCurrentUserAdminOrManager
        ? [
              { label: "Horas", value: "schedules" },
              { label: "Férias", value: "holiday" },
              { label: "Baixa", value: "sick_leave" },
          ]
        : [{ label: "Horas", value: "schedules" }];

    if (fieldsStatus === "loading") return null;

    return (
        <Modal
            open={isModalOpen}
            onClose={() => {
                workLogForm.reset();
                setIsModalOpen(false);
            }}
        >
            <Paper sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: 400, p: 4 }}>
                <form onSubmit={workLogForm.handleSubmit(handleSave)}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <ClinicsDropdown entityForm={workLogForm} fieldName="clinic_id" />
                        </Grid>
                        <Grid item xs={12}>
                            {startDateField &&
                                renderFieldInput(startDateField, null, "group", workLogForm, {
                                    disabled: disableDateFields,
                                })}
                        </Grid>
                        <Grid item xs={12}>
                            {endDateField &&
                                renderFieldInput(endDateField, null, "group", workLogForm, {
                                    disabled: disableDateFields,
                                })}
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="entry-type-label">Tipo de Registo</InputLabel>
                                <Controller
                                    name="type"
                                    control={workLogForm.control}
                                    render={({ field }) => (
                                        <Select
                                            labelId="entry-type-label"
                                            label="Tipo de Registo"
                                            value={field.value}
                                            onChange={(e) => {
                                                field.onChange(e.target.value);
                                            }}
                                            fullWidth
                                        >
                                            {types.map((type) => (
                                                <MenuItem key={type.value} value={type.value}>
                                                    {type.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            {noteField && renderFieldInput(noteField, null, "group", workLogForm)}
                        </Grid>
                    </Grid>
                    <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
                        Guardar
                    </Button>
                </form>
            </Paper>
        </Modal>
    );
};
