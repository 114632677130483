import { useState } from "react";
import { Select, MenuItem, FormControl, InputLabel, SelectChangeEvent } from "@mui/material";
import { FormatedField } from "../../../types/fields.types";
import { useUpdateRecord } from "../../../api/useUpdateRecord";
import { CalendarEvent } from "../../../components/calendar/calendar.types";
import { Session } from "../../../types/records.types";

type SessionStatusFieldProps = {
  field: FormatedField;
  value: string;
  event: CalendarEvent | undefined;
  session: Session;
  setPreviewIsOpen: (value: boolean) => void;
  refetchSessions: () => void;
};

export const SessionStatusField = ({
  field,
  value,
  event,
  setPreviewIsOpen,
  refetchSessions,
}: SessionStatusFieldProps) => {
  const [inputValue, setInputValue] = useState<string | null>(value);
  const updateStatus = useUpdateRecord();

  const handleChange = async (changeEvent: SelectChangeEvent) => {
    const eventId = event?.id;
    const statusValue = changeEvent.target.value;

    const response = await updateStatus.mutateAsync({
      entity: "sessions",
      id: eventId,
      data: {
        status: statusValue,
      },
    });

    if (!response.id) return;

    setPreviewIsOpen(false);
    refetchSessions();

    setInputValue(statusValue);
  };

  return (
    <FormControl fullWidth>
      <InputLabel>Estado da sessão</InputLabel>
      <Select value={inputValue || ""} onChange={handleChange} label="Estado da sessão" sx={{ height: 56 }}>
        {field.settings?.options?.choices?.map((choice) => {
          return (
            <MenuItem key={choice.value} value={choice.value}>
              {choice.text}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
