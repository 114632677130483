import { Dvr, Troubleshoot, Waves } from "@mui/icons-material";
import { Box, Button, SxProps, Theme } from "@mui/material";
import { Link } from "react-router-dom";
import { createRelatedParams, evaluationFilter } from "./utils";
import { neuroFeebackDataFilter, protocolFilter } from "./utils";
import { NeurofeedbackView, nfbDataFieldsQuery } from "./NeurofeedbackRelated";
import { evaluationsFieldsQuery } from "../../../evaluations/evaluationRoutes";
import { nfbProtocolFieldsQuery } from "../../../protocols/protocolRoutes";

type ToggleNeurofeedbackItemsProps = {
    setNeurofeedbackView: React.Dispatch<React.SetStateAction<NeurofeedbackView>>;
    neurofeedbackView: NeurofeedbackView;
    style?: SxProps<Theme>;
    treatmentId: string;
};

export const ToggleNeurofeedbackItems = ({
    setNeurofeedbackView,
    neurofeedbackView,
    treatmentId,
}: ToggleNeurofeedbackItemsProps) => {
    return (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexGrow: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
                <ChangeButton
                    neurofeedbackView={neurofeedbackView}
                    setNeurofeedbackView={setNeurofeedbackView}
                    entity="neurofeedback_data"
                    icon={<Waves />}
                    label="Neurofeedback Dados"
                    filter={neuroFeebackDataFilter}
                    fieldsQuery={nfbDataFieldsQuery}
                />
                <ChangeButton
                    neurofeedbackView={neurofeedbackView}
                    setNeurofeedbackView={setNeurofeedbackView}
                    entity="evaluations"
                    icon={<Troubleshoot />}
                    label="Avaliações"
                    filter={evaluationFilter}
                    fieldsQuery={evaluationsFieldsQuery}
                    relatedParams={""}
                />
                <ChangeButton
                    neurofeedbackView={neurofeedbackView}
                    setNeurofeedbackView={setNeurofeedbackView}
                    entity="protocols"
                    icon={<Dvr />}
                    label="Protocolos"
                    filter={protocolFilter}
                    fieldsQuery={nfbProtocolFieldsQuery}
                />
            </Box>
            <Box>
                {neurofeedbackView.entity === "protocols" && (
                    <Button component={Link} to={`/protocols/create?${createRelatedParams("protocols", treatmentId)}`}>
                        Criar
                    </Button>
                )}
            </Box>
        </Box>
    );
};

type ChangeButtonProps = {
    neurofeedbackView: NeurofeedbackView;
    setNeurofeedbackView: React.Dispatch<React.SetStateAction<NeurofeedbackView>>;
    entity: string;
    icon: React.ReactNode;
    label: string;
    filter: (parentId: string) => any;
    fieldsQuery: string;
    relatedParams?: string;
};

const ChangeButton = ({
    neurofeedbackView,
    setNeurofeedbackView,
    entity,
    icon,
    label,
    filter,
    fieldsQuery,
    relatedParams,
}: ChangeButtonProps) => {
    return (
        <Button
            variant={neurofeedbackView.entity === entity ? "contained" : "outlined"}
            onClick={() => setNeurofeedbackView({ entity, fieldsQuery, filter, relatedParams })}
            startIcon={icon}
            color="secondary"
        >
            {label}
        </Button>
    );
};

