import { format, addDays, isBefore, isAfter, setHours, setMinutes } from "date-fns";
import { useRecords } from "../../../api/useRecords";
import { createCalendarFilter } from "../../../components/calendar/calendarUtils";
import { CustomEventType, Schedule, FormattedSchedule, CategoryKey, CategoryWithTotals, CategoryUser } from "./types";
import { weekDays, CATEGORIES_CONFIG, FIRST_ROOMS_ID, CLINIC_BREAKS } from "../../../utils/constants";
import { getISODay } from "date-fns";
import { mapValues, orderBy } from "lodash";
import { useStore } from "../../../store/store";
import { getRelateServicesFromConstants } from "../../../utils/getRelatedServicesFromConstants";

export const workDays = weekDays.slice(0, 6);

export const createFormattedSchedules = (
  startDate: Date,
  endDate: Date,
  user: string,
  clinic: string,
  schedules: Schedule,
  room: number
) => {
  const adjustedEndDate = new Date(endDate);
  adjustedEndDate.setHours(23, 59, 59, 999);

  const formattedSchedules: Array<FormattedSchedule> = [];

  let currentDate = startDate;

  while (isBefore(currentDate, adjustedEndDate) || currentDate.getTime() === adjustedEndDate.getTime()) {
    const dayOfWeek = format(currentDate, "EEEE");
    const daySchedules = schedules[dayOfWeek];
    const iterationDate = new Date(currentDate);

    daySchedules.forEach((slot) => {
      if (slot.start && slot.end && slot.services) {
        const startDateTime = new Date(slot.start);
        const endDateTime = new Date(slot.end);

        const startHour = startDateTime.getHours();
        const startMinute = startDateTime.getMinutes();
        const endHour = endDateTime.getHours();
        const endMinute = endDateTime.getMinutes();

        const slotStartDateTime = setMinutes(setHours(iterationDate, startHour), startMinute);
        const slotEndDateTime = setMinutes(setHours(iterationDate, endHour), endMinute);

        const isWithinDateRange = !isBefore(slotStartDateTime, startDate) && !isAfter(slotEndDateTime, adjustedEndDate);

        if (isWithinDateRange) {
          formattedSchedules.push({
            start_date: slotStartDateTime.toISOString(),
            end_date: slotEndDateTime.toISOString(),
            user: user,
            clinic_id: clinic,
            room: room,
            services: slot.services.map((service) => ({
              schedules_id: "+",
              services_id: { id: service.id },
            })),
            notes: slot.notes || "",
          });
        }
      }
    });

    currentDate = addDays(currentDate, 1);
  }

  return formattedSchedules;
};

export const useStaffAvailability = (filter: any) => {
  const filters = createCalendarFilter(filter);

  const { data, status, refetch } = useRecords("schedules", "*,services.*.*,user.*.*", {
    extraOptions: { filter: filters },
  });

  const { data: timeOff, status: timeOffStatus } = useRecords("time_off", "*,user.*.*", {
    extraOptions: { filter: filters },
  });

  const mappedEvents =
    data?.items.map((schedule: any) => {
      return {
        id: schedule.id,
        clinicId: schedule.clinic_id,
        title: schedule.services?.map((service: any) => service.services_id?.name).join(", ") || "Unspecified",
        services: schedule.services?.map((service: any) => service.services_id?.id),
        start: new Date(schedule.start_date),
        end: new Date(schedule.end_date),
        notes: schedule.notes,
        userId: schedule.user?.id,
        userColor: schedule.user?.technician_id[0]?.color,
        userName: `${schedule.user?.first_name} ${schedule.user?.last_name}`,
        userAvatar: schedule?.user?.avatar?.id,
        type: "schedule",
        room: schedule.room || 9,
      };
    }) || [];

  const scheduleEvents = orderBy(mappedEvents, ["userName"], ["asc"]) as CustomEventType[];

  const timeOffEvents =
    timeOff?.items.map((timeOff: any) => {
      const technicianType = timeOff?.user?.technician_id[0]?.specializations;
      return {
        id: timeOff.id,
        start: new Date(timeOff.start_date),
        end: new Date(timeOff.end_date),
        notes: timeOff.notes,
        userColor: "#e63131",
        userName: `${timeOff.user?.first_name} ${timeOff.user?.last_name}`,
        userAvatar: timeOff?.user?.avatar?.id,
        type: "time_off",
        allDay: true,
        room: technicianType?.includes("neurofeedback") ? FIRST_ROOMS_ID.nfb : FIRST_ROOMS_ID.psy,
      };
    }) || ([] as CustomEventType[]);

  const isLoading = status === "loading" || timeOffStatus === "loading";
  const isError = status === "error" || timeOffStatus === "error";

  const events = [...scheduleEvents, ...timeOffEvents] as CustomEventType[];

  return { events, isLoading, isError, refetch };
};

export const calculateHours = (startDate: Date, endDate: Date): number => {
  const diffInHours = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60);
  return Number(diffInHours.toFixed(1));
};

export const calculateTotalHours = (events: Array<CustomEventType>): number => {
  const totalHours = events.reduce((total, event) => {
    return total + calculateHours(new Date(event.start), new Date(event.end));
  }, 0);

  return Number(totalHours.toFixed(1));
};

export const getScheduleTotals = (userEvents: Array<CustomEventType>) => {
  const dailyTotals = workDays.reduce((acc, day) => {
    acc[day] = 0;
    return acc;
  }, {} as Record<string, number>);

  let weeklyTotal = 0;

  userEvents.forEach((event) => {
    const dayIndex = getISODay(event.start) - 1;
    const day = weekDays[dayIndex];
    const hours = calculateHours(event.start, event.end);

    dailyTotals[day] += hours;
    weeklyTotal += hours;
  });

  return { dailyTotals, weeklyTotal };
};

type WithUserName = { userName: string };

export const groupEventsByUser = <T extends CustomEventType>(events: Array<T>): Record<string, CategoryUser> => {
  const result = events.reduce((acc, event) => {
    if (!acc[event.userId]) {
      acc[event.userId] = {
        userName: event.userName,
        userColor: event.userColor,
        userId: event.userId,
        userAvatar: event.userAvatar,
        events: [],
      };
    }
    acc[event.userId].events.push(event);
    return acc;
  }, {} as Record<string, CategoryUser>);

  return result;
};

export const sortByUserName = <T extends WithUserName>(items: T[] | Record<string, T>): Array<T> => {
  const itemsArray = Array.isArray(items) ? items : Object.values(items);

  const result = itemsArray.sort((a, b) => a.userName.localeCompare(b.userName, "pt-PT"));

  return result;
};

export const categorizeEvents = (events: Array<CustomEventType>): CategoryWithTotals => {
  const categories = mapValues(CATEGORIES_CONFIG, (category) => ({
    ...category,
    users: {},
    dailyTotals: {},
    possibleSessions: {},
  })) as CategoryWithTotals;

  // Initialize dailyTotals for each category
  Object.keys(categories).forEach((categoryKey) => {
    workDays.forEach((day) => {
      categories[categoryKey as CategoryKey].dailyTotals[day] = 0;
    });
  });

  // Process events
  events.forEach((event) => {
    const categoryKey = determineEventCategoryKey(event.services);
    const category = categories[categoryKey];

    // Add user if not exists
    if (!category.users[event.userId]) {
      category.users[event.userId] = {
        userName: event.userName,
        userAvatar: event.userAvatar,
        userColor: event.userColor,
        userId: event.userId,
        events: [],
      };
    }

    // Add event to user
    category.users[event.userId].events.push(event);

    // Update daily totals
    const dayOfWeek = format(event.start, "EEEE");
    const hours = calculateHours(event.start, event.end);
    category.dailyTotals[dayOfWeek] += hours;
  });

  // Calculate possible sessions and sort users
  Object.entries(categories).forEach(([categoryKey, category]) => {
    // Calculate possible sessions
    workDays.forEach((day) => {
      category.possibleSessions[day] = calculatePossibleSessions(
        category.dailyTotals[day],
        category.duration,
        category.timeForSessions
      );
    });

    category.possibleSessions.weekly = calculatePossibleSessions(
      Object.values(category.dailyTotals).reduce((acc, curr) => acc + curr, 0),
      category.duration,
      category.timeForSessions
    );

    // Sort users within each category
    const sortedUsers = sortByUserName(category.users);
    category.users = Object.fromEntries(sortedUsers.map((user) => [user.userId, user]));
  });

  return categories;
};

const determineEventCategoryKey = (eventServices: Array<number> = []): CategoryKey => {
  const foundCategory = Object.entries(CATEGORIES_CONFIG).find(([_, config]) =>
    eventServices.some((service: number) => {
      const relatedServicesIds = getRelateServicesFromConstants(config.key as "nfb" | "psy" | "reports");
      return relatedServicesIds.includes(service);
    })
  );

  return (foundCategory?.[0] as CategoryKey) || "others";
};

const calculatePossibleSessions = (hours: number, serviceDuration: number, timeForSessions: number) => {
  const totalMinutes = hours * 60;
  if (totalMinutes === 0 || !serviceDuration) return 0;
  return Math.floor((totalMinutes / serviceDuration) * timeForSessions);
};

export const useFilteredSchedules = (events: Array<CustomEventType>) => {
  const {
    schedules: { eventTypeFilter, servicesSelected, userId },
  } = useStore();

  if (!events) return [];

  const filteredEvents = events.filter((event) => {
    const typeMatch = eventTypeFilter === "all" || event.type === eventTypeFilter;
    const serviceMatch =
      servicesSelected.length === 0
        ? true
        : event?.services?.length === 0
        ? servicesSelected.includes(9999)
        : event?.services?.some((serviceId) => servicesSelected.includes(serviceId));
    const userMatch = !userId || event.userId === userId;

    return typeMatch && serviceMatch && userMatch;
  });

  return filteredEvents;
};

export const getSessionCountsByCategory = (categoryIds: ReadonlyArray<number>, sessionsData: any) => {
  const validStatuses = ["booked", "confirmed", "done"];

  const sessionCounts = {
    daily: {} as Record<string, number>,
    total: 0,
  };

  if (!sessionsData?.items) {
    return sessionCounts;
  }

  const validSessions = sessionsData.items.filter(
    (session: any) => categoryIds.includes(session.service) && validStatuses.includes(session.status)
  );

  // Initialize daily counts
  workDays.forEach((day) => {
    sessionCounts.daily[day] = validSessions.filter((session: any) => {
      return format(new Date(session.start_date), "EEEE") === day;
    }).length;
  });

  sessionCounts.total = validSessions.length;

  return sessionCounts;
};

export const customSlotPropGetter = (date: Date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const timeInMinutes = hours * 60 + minutes;

  const lunchStartInMinutes = CLINIC_BREAKS.lunch.start.hours * 60 + CLINIC_BREAKS.lunch.start.minutes;
  const lunchEndInMinutes = CLINIC_BREAKS.lunch.end.hours * 60 + CLINIC_BREAKS.lunch.end.minutes;

  if (timeInMinutes >= lunchStartInMinutes && timeInMinutes < lunchEndInMinutes) {
    return {
      style: {
        backgroundColor: "#D67171",
      },
      "data-lunch": "true",
    };
  }
  return {};
};

export const getFilteredResources = (resources: Array<any> | undefined, selectedIds: Array<number>) => {
  if (!resources) return [];
  return selectedIds.length === 0 ? resources : resources.filter((resource) => selectedIds.includes(resource.id));
};

export const getRoomIdsByType = (resources: Array<any> | undefined, type: "nfb" | "psy"): Array<number> => {
  if (!resources) return [];
  return resources.filter((room) => room.type === type).map((room) => room.id);
};



export const calculateDaysWithHours = (dailyTotals: Record<string, number>) => {
  let daysWithHours = 0;

  for (const day in dailyTotals) {
      if (dailyTotals[day] > 0) {
          daysWithHours++;
      }
  }

  const result = daysWithHours;
  return result;
};
