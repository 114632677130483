import { useFields } from "../../api/useFields";
import { FeedbackData, NFBfeedback, NFBsessionGradeFieldsConfig, NFBsessionGradeValues, ParentGroup } from "./types";
import { Choice, getChoiceLabel } from "../../utils/choiceUtils";

export const fieldsQueryArray = [
    "*.*",
    "session.treatment.client.user_id.first_name",
    "session.treatment.client.user_id.last_name",
    "session.treatment.client.client_id",
    "session.treatment.id",
    "session.treatment.permanent_note",
    "session.technician.id",
    "session.technician.user_id.*",
    "protocol.*",
    "protocol.neurofeedback_goal.*",
    "protocol.neurofeedback_goal.frequency_band.*",
    "protocol.factors.factors_id.*",
    "protocol.electrodes_locations.electrodes_locations_id.*",
    "protocol.frequency_bands.frequency_bands_id.*",
    "nfb_feedback.*",
    "nfb_feedback.nfb_feedback_type.*",
];

export const extractArrayFromManyToMany = (manyToMany: any[], key: string) => {
    if (!manyToMany) return [];
    return manyToMany.map((item) => item[`${key}_id`]);
};

export const calculateAverages = (group: ParentGroup) => {
    const calculate = (type: "technician" | "supervisor") => {
        let total = 0;
        let count = 0;

        group.subgroups.forEach((subgroup) => {
            subgroup.fields.forEach((field) => {
                if (field.type === type) {
                    total += Number(field.value) || 0;
                    count++;
                }
            });
        });
        return count > 0 ? total / count : 0;
    };

    const technicianAverage = calculate("technician");
    const supervisorAverage = calculate("supervisor");

    return { technicianAverage, supervisorAverage };
};

export const calculateGlobalGrade = (groups: Array<ParentGroup>, clientCollaboration: number) => {
    if (!groups || !clientCollaboration) return 0;

    const calculate = (type: "technician" | "supervisor") => {
        let total = 0;
        let count = 0;

        groups.forEach((group) => {
            group.subgroups.forEach((subgroup) => {
                subgroup.fields.forEach((field) => {
                    if (field.type === type) {
                        total += Number(field.value) || 0;
                        count++;
                    }
                });
            });
        });
        const average = count > 0 ? total / count : 0;
        return average;
    };

    const totalTechnicianAverage = calculate("technician");
    const sessionGlobalGrade = (totalTechnicianAverage + clientCollaboration) / 2;

    return sessionGlobalGrade;
};

export const createGroupAndSubGroups = (groupFields: Array<NFBsessionGradeFieldsConfig>) => {
    return groupFields.reduce((acc: Array<ParentGroup>, field: NFBsessionGradeFieldsConfig) => {
        const settings = field.meta;

        // Handle main groups (tables)
        if (settings.interface === "group-raw" && !settings.group) {
            acc.push({
                title: settings.note,
                groupField: settings.field,
                type: "parentGroup",
                subgroups: [],
            });
        }

        // Handle subgroups
        if (settings.interface === "group-raw" && settings.group) {
            const parentGroup = acc.find((group) => group.groupField === settings.group);

            if (parentGroup) {
                parentGroup.subgroups.push({
                    title: settings.note,
                    groupField: settings.field,
                    type: "subGroup",
                    fields: [],
                });
            }
        }

        return acc;
    }, []);
};

export const getOnlyGroupFields = (rawFields: Array<NFBsessionGradeFieldsConfig>) => {
    const excludeGroups = [
        "technical_part",
        "technician_artificats_impedence_question",
        "technician_feedback_and_thresholds_question",
        "technician_guide_client_question",
        "nfb_session",
    ];
    const filteredFields = rawFields.filter(
        (field) => field.meta.interface === "group-raw" && !excludeGroups.includes(field.field)
    );

    const sortedFields = filteredFields.sort((a, b) => {
        if (!a.meta.field) return 1;
        if (!b.meta.field) return -1;
        return a.meta.field.localeCompare(b.meta.field);
    });

    return sortedFields;
};

export const addFieldsToSubGroup = (
    groupAndSubGroups: Array<ParentGroup>,
    fields: Array<NFBsessionGradeFieldsConfig>,
    nfb_session_grade: NFBsessionGradeValues
) => {
    if (!groupAndSubGroups || !fields || !nfb_session_grade) return [];

    const newGroupAndSubGroups = groupAndSubGroups.map((group) => {
        const newSubGroups = group.subgroups.map((subGroup) => {
            const newFields = fields.filter((field) => field.meta.group === subGroup.groupField);

            return {
                ...subGroup,
                fields: newFields.map((field) => {
                    return {
                        field: field.field,
                        value: nfb_session_grade[field.field as keyof NFBsessionGradeValues],
                        type: field.field.includes("supervisor") ? "supervisor" : "technician",
                    };
                }),
            };
        });
        return { ...group, subgroups: newSubGroups };
    });

    return newGroupAndSubGroups;
};

export const formatNumberWithDecimal = (value: number): string => {
    const formattedValue = Number.isInteger(value) ? `${value}.0` : `${value}`;
    return formattedValue;
};

export const generateFeedbackData = (feedbacks: NFBfeedback[], secondaryFeedbackChoices: Choice[] = []): Array<FeedbackData> => {
    return feedbacks.map((feedback) => {
        const positiveValue = formatNumberWithDecimal(feedback.threshold_positive);
        const negativeValue = formatNumberWithDecimal(Math.abs(feedback.threshold_negative));

        // Get the label for the secondary feedback dropdown value
        const secondaryFeedback = getChoiceLabel(secondaryFeedbackChoices, feedback.nfb_secondary_feedback);

        return {
            id: feedback.id,
            minute: feedback?.minute_applied?.toString(),
            type: `${feedback?.nfb_feedback_type?.name} / ${feedback?.nfb_feedback_type?.type}`,
            scale: `${positiveValue}/-${negativeValue}`,
            information: feedback?.notes,
            secondary_feedback: secondaryFeedback,
        };
    });
};

export const useSessionGrade = (nfbSessionGrade: NFBsessionGradeValues) => {
    const defaultReturn = { sessionGlobalGrade: 0, fieldsOrganizedInGroups: [], techAverage: 0, supAverage: 0 };

    const { rawFields, status: fieldsStatus } = useFields("nfb_session_grades");

    if (fieldsStatus === "loading") return defaultReturn;
    if (fieldsStatus === "error") return defaultReturn;
    if (!nfbSessionGrade) return defaultReturn;

    const groupFields = getOnlyGroupFields(rawFields);
    const groupAndSubGroups = createGroupAndSubGroups(groupFields);
    const fieldsOrganizedInGroups = addFieldsToSubGroup(groupAndSubGroups, rawFields, nfbSessionGrade) as Array<ParentGroup>;
    let sessionGlobalGrade = calculateGlobalGrade(fieldsOrganizedInGroups, nfbSessionGrade?.client_collaboration);
    sessionGlobalGrade = Number(sessionGlobalGrade.toFixed(1));

    let techAverage = 0;
    let supAverage = 0;

    fieldsOrganizedInGroups.forEach((group) => {
        const { technicianAverage, supervisorAverage } = calculateAverages(group);
        techAverage = technicianAverage;
        supAverage = supervisorAverage;
    });

    return { sessionGlobalGrade, fieldsOrganizedInGroups, techAverage, supAverage };
};
