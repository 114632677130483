import store2 from "store2";
import { SubscriptionData } from "../api/useWebSockets";
import { useUser } from "../api/useAuth";

export const useLogoutFromDevice = () => {
    const { data: currentUser } = useUser();
    const logOutFromDevice = (subscriptionData: SubscriptionData) => {
        const { event, uid, data } = subscriptionData;

        // Only process "create" events
        if (event !== "create") return;

        const record = data[0];

        // Check if the record has an "action" field with "logout" value
        if (record.action === "logout") {
            // Check if this logout is for the current user
            if (currentUser && record.user?.id === currentUser.id) {
                // Perform logout by clearing storage cache
                store2.clearAll();
                window.location.reload();
            }
        }
    };

    return logOutFromDevice;
};
