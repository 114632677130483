export const APP_USERS_ROLES_FILTER = {
    _and: [
        {
            role: {
                id: {
                    _neq: "33b88ee8-1af3-4b8f-a2aa-ebe5ba596625",
                },
            },
        },
    ],
} as any;

export const ROLES = {
    admin: "d6b0779e-70fc-4854-9525-91e41e555389",
    shilan: "272be022-2246-4bfc-867b-3c14bd6d54b9",
    neurofeedback_director: "af059eb1-ed68-4fb1-a0bd-a0a122f21eed",
    reception_director: "444915ca-8738-4b34-8e1c-fb3da4a54d03",
    technical_director: "c3aaca5c-9e37-499e-b061-74690007fe0f",
    nfb_techncians: "55720c74-c013-44f0-96e5-3737adf14963",
    nfb_and_theurapist: "2d7fadc9-2996-439c-9568-b30ecffd91cd",
    theurapist: "0295dd26-17ab-454c-bacf-044dd349f21e",
    recepcionist: "3b835836-7e73-4f72-af50-597f9a49261f",
    other: "0675b953-7b2a-4778-b578-2818b22794d8",
    accounting: "83e16f3b-d544-433d-8f55-466c51b1246a",
    virtual_assistant: "be3622e2-3796-4458-9c8f-e4738c5b4524",
    callcenter: "ef356faa-3c5e-4f8d-851f-e9fb14fcc372",
    tech_people: "96282fd0-acd0-4db4-970a-641e9ca271ab",
    clients: "33b88ee8-1af3-4b8f-a2aa-ebe5ba596625",
    marketing_bot: "b81935bf-74f0-47c4-a336-f9492743a3df",
    make: "3b3ae5ad-b291-47fb-ad33-2d1af245ec26"
};

export const SPECIAL_USERS = {
    joana_director: "453f4b6d-3bd8-4838-ba27-36c6bc43e5e7",
};

export const weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

export const weekDaysTranslation: Record<string, string> = {
    Monday: "Segunda-feira",
    Tuesday: "Terça-feira",
    Wednesday: "Quarta-feira",
    Thursday: "Quinta-feira",
    Friday: "Sexta-feira",
    Saturday: "Sábado",
    Sunday: "Domingo",
};

export const OVERBOOKED_RATIO = 0.9;

export const CATEGORIES_CONFIG = {
    nfb: {
        key: "nfb",
        name: "Neurofeedback",
        services: [
            {
                id: 33,
            },
            {
                id: 35,
            },
            {
                id: 39,
            },
        ],

        duration: 45,
        timeForSessions: 1,
    },
    psy: {
        key: "psy",
        name: "Psychology",
        services: [
            {
                id: 34,
            },
            {
                id: 36,
            },
        ],

        duration: 60,
        timeForSessions: 1,
    },
    reports: {
        key: "reports",
        name: "Reports",
        services: [
            {
                id: 37,
            },
        ],
        duration: 45,
        timeForSessions: 1,
    },
    others: {
        key: "others",
        name: "Others",
        services: [],
        duration: 60,
        timeForSessions: 1,
    },
};

export const CLINIC_SCHEDULES = {
    morning: {
        start: { hours: 9, minutes: 0 },
        end: { hours: 13, minutes: 0 },
    },
    afternoon: {
        start: { hours: 14, minutes: 0 },
        end: { hours: 21, minutes: 0 },
    },
};

export const CLINIC_BREAKS = {
    lunch: {
        start: { hours: 12, minutes: 45 },
        end: { hours: 14, minutes: 0 },
    },
    afternoon: {
        start: { hours: 17, minutes: 0 },
        end: { hours: 17, minutes: 15 },
    },
};

export const MAX_WORK_HOURS = {
    day: 9,
    week: 40,
};

export const FIRST_ROOMS_ID = {
    nfb: 1,
    psy: 6,
};


export const ORDERS_SINCE_DATE = "2025-01-01";

export const BANK_TRANSACTIONS_SINCE_DATE = "2025-01-01";

export const CASH_ACCOUNT_ID = 92;


export const TIMEZONE = "Europe/Lisbon";

export const EEG_SERVICES = [35, 39];
export const NFB_SERVICE = 33;