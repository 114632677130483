import { Box } from "@mui/material";
import { FrequencyBands } from "../protocol/FrequencyBands";
import { ElectrodeLocations } from "../protocol/ElectrodeLocations";
import { Factors } from "../protocol/Factors";

type NeurofeedbackProtocolGridFieldProps = {
    value: any;
};

export const NeurofeedbackProtocolGridField = ({ value }: NeurofeedbackProtocolGridFieldProps) => {
    const frequencyBands = value.protocol?.frequency_bands;
    const electrodesLocations = value.protocol?.electrodes_locations;
    const protcolFactors = value.protocol?.factors;
    return (
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
            <ElectrodeLocations electrodesLocations={electrodesLocations} />
            <FrequencyBands frequencyBands={frequencyBands} />
            <Factors factors={protcolFactors} />
        </Box>
    );
};
