import { Box, Button, Divider, Grid, Paper, Typography } from "@mui/material";
import { Wysiwyg } from "../../../components/wysiwyg/Wysiwyg";
import { useForm } from "react-hook-form";
import { useUpdateRecord } from "../../../api/useUpdateRecord";
import { useStore } from "../../../store/store";

type NeurofeedbackSessionNotesProps = {
    sessionNotes: string;
    sessionId: number;
    permanentNote: string;
};

export const NeurofeedbackSessionNotes = ({ sessionNotes, sessionId, permanentNote }: NeurofeedbackSessionNotesProps) => {
    const entityForm = useForm();
    const updateRecord = useUpdateRecord();
    const { notifications } = useStore();

  
    const onSubmit = async (data: any) => {
        try {
            await updateRecord.mutateAsync({
                entity: "neurofeedback_data",
                id: sessionId,
                data: {
                    notes: data.content,
                },
            });
            notifications.setNotification({
                isOpen: true,
                severity: "success",
                message: "Notas guardadas",
            });
        } catch (error) {
            notifications.setNotification({
                isOpen: true,
                severity: "error",
                message: "Erro a guardar as notas",
            });
        }
    };

    console.log("permanentNote=>", permanentNote);
    return (
        <Box component="form" onSubmit={entityForm.handleSubmit(onSubmit)} sx={{ padding: 2 }}>
            <Grid container spacing={4}>
                <Grid item xs={12} md={8}>
                    <Paper
                        elevation={2}
                        sx={{
                            padding: 3,
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
                            <Typography variant="h6" color="primary" fontWeight="medium">
                                Notas da sessão
                            </Typography>
                            <Button type="submit" variant="contained" color="primary" size="medium">
                                Guardar
                            </Button>
                        </Box>
                        <Wysiwyg entityForm={entityForm} initialValue={sessionNotes} />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper
                        elevation={2} 
                        sx={{
                            padding: 4,
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Typography variant="h6" color="primary" sx={{ mb: 3 }} fontWeight="medium">
                            Nota Permanente
                        </Typography>
                        <Divider sx={{ mb: 3 }} />
                        <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
                            {permanentNote}
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

