import { UseRecordsOptions } from "../../api/useRecords";
import { filterUsersRoles } from "../../utils/filterUsersRoles";

export const staffAgreementsOptions = {
    fieldsToRemove: {
        fieldNamesToRemove: ["id", "content"],
        fieldTypesToRemove: ["alias"],
    },
    groupsOptions: {
        fieldsWithExtraOptions: [
            {
                field: "user",
                filter: filterUsersRoles,
                enabled: true
            }
        ],
    },
} as unknown as UseRecordsOptions;
