import {
    Dialog,
    DialogTitle,
    Button,
    DialogContent,
    DialogActions,
    TextField,
    CircularProgress,
    MenuItem,
    FormControl,
    Select,
    InputLabel,
} from "@mui/material";
import { useStore } from "../../store/store";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import { useState, useEffect } from "react";
import { useRelatedRecords } from "../../api/useRelatedRecords";

const defaultTreatmentPrescription = 
`Durante a sua avaliação inicial, foi prescrito o seguinte plano de tratamento:
- X sessões de Neurofeedback, realizadas duas vezes por semana.
- X sessões de Psicologia, realizadas uma vez por semana.
- X Reuniões de Acompanhamento, realizadas uma vez por mês.
- 2 qEEG (Eletroencefalograma quantitativo): um após a vigésima sessão de Neurofeedback e outro no final do tratamento.

*Salienta-se e salvaguarda-se que o número de sessões indicado é uma estimativa e pode sofrer alterações consoante a evolução do tratamento.
`;


export const TreatmentAndPaymentInformation = () => {
    const { treatmentAndPaymentInformation, notifications } = useStore();
    const [isLoading, setIsLoading] = useState(false);
    const { data: relatives } = useRelatedRecords("relatives", "*.*", "client_id", treatmentAndPaymentInformation.context?.item?.client?.id);

    const emails = possibleEmails(  treatmentAndPaymentInformation.context?.item, relatives?.items);
    const entityForm = useForm({
        defaultValues: {
            treatment_prescription: defaultTreatmentPrescription,
            email_to_send: "",
        },
    });

    useEffect(() => {
        if (emails?.[0]?.email) {
            entityForm.setValue("email_to_send", emails[0].email);
        }
    }, [emails]);

    const handleSend = async (formData: { treatment_prescription: string; email_to_send: string }) => {
        if (!formData.treatment_prescription) {
            alert("Preencha todos os campos");
            return;
        }
        notifications.setNotification({
            isOpen: false,
        });
        setIsLoading(true);

        const treatment = treatmentAndPaymentInformation.context.item;
        try {
            await axios({
                method: "POST",
                url: "https://hook.eu1.make.com/qfcbcdgkloclu5rvmaaodlms82c6fqpk",
                data: {
                    treatment_id: treatment.id,
                    client_number: treatment.client.client_id,
                    treatment_prescription: formData.treatment_prescription,
                    first_name: treatment.client?.user_id?.first_name,
                    email: formData.email_to_send,
                },
            });

            notifications.setNotification({
                isOpen: true,
                message: `Informação de tratamento e pagamento enviada com sucesso para email ${treatmentAndPaymentInformation.context.item.client?.user_id?.email}`,
                severity: "success",
            });

            entityForm.reset();
            treatmentAndPaymentInformation.setIsOpen(false);
        } catch (error: any) {
            const errorMessage = error?.response?.data?.errorMessage || "Erro ao enviar link de pagamento";
            notifications.setNotification({
                isOpen: true,
                message: errorMessage,
                severity: "error",
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={treatmentAndPaymentInformation.isOpen} onClose={() => treatmentAndPaymentInformation.setIsOpen(false)} maxWidth="md" fullWidth>
            <DialogTitle>Enviar informação de tratamento e pagamento</DialogTitle>
            <form onSubmit={entityForm.handleSubmit(handleSend)}>
                <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                    <Controller
                        name="treatment_prescription"
                        control={entityForm.control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                autoFocus
                                margin="dense"
                                label="Descrição"
                                type="text"
                                fullWidth
                                multiline
                                rows={12}
                            />
                        )}
                    />
                    <Controller
                        name="email_to_send"
                        control={entityForm.control}
                        render={({ field: { onChange, value, ...field } }) => (
                            <FormControl fullWidth>
                                <InputLabel>Email para enviar o link</InputLabel>
                                <Select
                                    {...field}
                                    value={value}
                                    onChange={(e) => onChange(e.target.value)}
                                    label="Email para enviar o link"
                                >
                                    {emails &&
                                        emails.map((email: any) => (
                                            <MenuItem key={email.email} value={email.email}>
                                                {email.name} | {email.email} ({email.type === "relative" ? "Parente" : "Cliente"})
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={() => treatmentAndPaymentInformation.setIsOpen(false)}>
                        Cancelar
                    </Button>
                    <Button type="submit">{isLoading ? <CircularProgress color="inherit" size={24} /> : "Enviar"}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

const possibleEmails = (treatment: any, relatives?: { name: string; email: string }[]) => {
    if (!treatment) return;

    const possibleEmails = [
        {
            type: "client",
            email: treatment.client.user_id.email,
            name: `${treatment.client.user_id.first_name} ${treatment.client.user_id.last_name}`,
        },
    ];
    if (relatives && relatives?.length > 0) {
        relatives.forEach((relative: any) => {
            possibleEmails.push({
                type: "relative",
                email: relative.email,
                name: `${relative.Nome}`,
            });
        });
    }

    return possibleEmails;
};
