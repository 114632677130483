import { format } from "date-fns";
import { create } from "zustand";
import { Store } from "./store.types";

const useStoreBase = create<Store>()((set) => ({
    recordsFilter: null,
    setRecordsFilter: (filters) => set(() => ({ recordsFilter: filters })),
    modalFilter: null,
    setModalFilters: (filters) => set(() => ({ modalFilter: filters })),
    progressFilter: null,
    setProgressFilter: (filters) => set(() => ({ progressFilter: filters })),
    clearEntityFilters: (entity) =>
        set((state) => {
            delete state.recordsFilter![entity];
            return { recordsFilter: state.recordsFilter };
        }),
    recordsSort: null,
    setRecordsSort: (sort) => set((state) => ({ ...state, recordsSort: sort })),
    calendar: {
        defaultDate: new Date().toISOString(),
        setDefaultDate: (date) =>
            set((state) => ({ calendar: { ...state.calendar, defaultDate: format(date, "yyyy-MM-dd HH:mm:ss") } })),
        calendarView: null,
        setCalendarView: (calendarView) => set((state) => ({ calendar: { ...state.calendar, calendarView: calendarView } })),
        calendarFilters: null,
        setCalendarFilters: (filters) => set((state) => ({ calendar: { ...state.calendar, calendarFilters: filters } })),
    },
    schedules: {
        defaultDate: new Date().toISOString(),
        setDefaultDate: (date) =>
            set((state) => ({ schedules: { ...state.schedules, defaultDate: format(date, "yyyy-MM-dd HH:mm:ss") } })),
        calendarView: "week",
        setCalendarView: (calendarView) => set((state) => ({ schedules: { ...state.schedules, calendarView: calendarView } })),
        calendarFilters: null,
        setCalendarFilters: (filters) => set((state) => ({ schedules: { ...state.schedules, calendarFilters: filters } })),
        servicesSelected: [],
        setServicesSelected: (services) => set((state) => ({ schedules: { ...state.schedules, servicesSelected: services } })),
        eventTypeFilter: "all",
        setEventTypeFilter: (eventType) => set((state) => ({ schedules: { ...state.schedules, eventTypeFilter: eventType } })),
        userId: "",
        setUserId: (userId) => set((state) => ({ schedules: { ...state.schedules, userId } })),
        resourceIds: [],
        setResourceIds: (resourceIds) => set((state) => ({ schedules: { ...state.schedules, resourceIds } })),
    },
    alerts: {
        isOpen: false,
        setIsOpen: (isOpen) => set((state) => ({ alerts: { ...state.alerts, isOpen: isOpen } })),
        allowExecution: false,
        setAllowExecution: (allowExecution) => set((state) => ({ alerts: { ...state.alerts, allowExecution: allowExecution } })),
        title: "Tem a certeza que quer apagar?",
        setTitle: (title) => set((state) => ({ alerts: { ...state.alerts, title: title } })),
        message: "Esta ação é irreversivel! irá apagar este registo permanentemente.",
        setMessage: (message) => set((state) => ({ alerts: { ...state.alerts, message: message } })),
    },
    notifications: {
        notification: {
            isOpen: false,
        },
        setNotification: (notification) =>
            set((state) => ({ notifications: { ...state.notifications, notification: notification } })),
        notificationsList: {
            isOpen: false,
        },
        setNotificationsList: (notificationsList) =>
            set((state) => ({ notifications: { ...state.notifications, notificationsList: notificationsList } })),
    },
    menu: {
        isOpen: false,
        setIsOpen: (isOpen) => set((state) => ({ menu: { ...state.menu, isOpen: isOpen } })),
    },
    gridSettings: {
        gridColumns: [],
        selectedSettings: undefined,
        activeOnGrid: undefined,
        entity: null,
        apiSettings: null,
        configColumnsModalIsOpen: false,
        configColumnsModalAnchorEl: null,
        scrollPosition: {
            top: 0,
            left: 0,
            entity: "",
        },
        gridIsLoading: false,
        paginationModel: {
            page: 0,
            pageSize: 100,
        },
        setGridSettings: (newSettings) =>
            set((state) => {
                return {
                    gridSettings: { ...state.gridSettings, ...newSettings },
                };
            }),
    },
    treatments: {
        view: "list",
        setView: (view) => set((state) => ({ treatments: { ...state.treatments, view: view } })),
    },
    quickSearchToolBar: {
        inputValue: {
            entity: "",
            inputValue: "",
        },
        setInputValue: (inputValue: { entity: string; inputValue: string }) =>
            set((state) => ({ ...state, quickSearchToolBar: { ...state.quickSearchToolBar, inputValue: inputValue } })),
    },
    phoneClient: {
        iframeRef: null,
        url: "",
        setUrl: (url: string) => set((state) => ({ ...state, phoneClient: { ...state.phoneClient, url: url } })),
        setPhoneNumberToCall: (iframeRef: React.RefObject<HTMLIFrameElement>, url: string, phoneNumberToCall: string) => {
            if (iframeRef.current && url) {
                iframeRef.current.contentWindow?.postMessage(
                    {
                        number: phoneNumberToCall,
                        autoCall: "false",
                    },
                    url
                );
            }
        },
    },
    sms: {
        isOpen: false,
        setIsOpen: (isOpen: boolean) => set((state) => ({ ...state, sms: { ...state.sms, isOpen: isOpen } })),
        context: null,
        setContext: (context: any) => set((state) => ({ ...state, sms: { ...state.sms, context: context } })),
    },
    payment: {
        isOpen: false,
        setIsOpen: (isOpen: boolean) => set((state) => ({ ...state, payment: { ...state.payment, isOpen: isOpen } })),
        context: null,
            setContext: (context: any) => set((state) => ({ ...state, payment: { ...state.payment, context: context } })),
    },
    treatmentAndPaymentInformation: {
        isOpen: false,
        setIsOpen: (isOpen: boolean) => set((state) => ({ ...state, treatmentAndPaymentInformation: { ...state.treatmentAndPaymentInformation, isOpen: isOpen } })),
        context: null,
        setContext: (context: any) => set((state) => ({ ...state, treatmentAndPaymentInformation: { ...state.treatmentAndPaymentInformation, context: context } })),
    },
    evaluation_report_dialog: {
        isOpen: false,
        setIsOpen: (isOpen: boolean) =>
            set((state) => ({ ...state, evaluation_report_dialog: { ...state.evaluation_report_dialog, isOpen: isOpen } })),
        context: null,
        setContext: (context: any) =>
            set((state) => ({ ...state, evaluation_report_dialog: { ...state.evaluation_report_dialog, context: context } })),
    },
    refetchFiles: {
        timestamp: 0,
        setTimestamp: (timestamp: number) =>
            set((state) => ({ ...state, refetchFiles: { ...state.refetchFiles, timestamp: timestamp } })),
    },
    staffWorkLogs: {
        selectedYear: new Date().getFullYear(),
        setSelectedYear: (year: number) => set((state) => ({ staffWorkLogs: { ...state.staffWorkLogs, selectedYear: year } })),
        selectedMonth: new Date().getMonth(),
        setSelectedMonth: (month: number) =>
            set((state) => ({ staffWorkLogs: { ...state.staffWorkLogs, selectedMonth: month } })),
    },
    processesSearch: {
        isOpen: false,
        setIsOpen: (isOpen: boolean) =>
            set((state) => ({ ...state, processesSearch: { ...state.processesSearch, isOpen: isOpen } })),
    },
    order: {
        isOpen: false,
        setIsOpen: (isOpen: boolean) => set((state) => ({ ...state, order: { ...state.order, isOpen: isOpen } })),
        context: null,
        setContext: (context: any) => set((state) => ({ ...state, order: { ...state.order, context: context } })),
    },
}));

// Export the hook for use in React components
export const useStore = useStoreBase;

// Export a function to access the store outside of React components
export const getStore = useStoreBase.getState;
