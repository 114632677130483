import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, Typography, TableBody } from "@mui/material";
import { NFBsessionGradeValues, ParentGroup } from "../../types";
import { NeurofeedbackSupervisorNotesGrade } from "./NeurofeedbackNotesGrade";

type NeurofeedbackSessionGradeConclusionProps = {
    nfbSessionGrade: NFBsessionGradeValues;
    fieldsOrganizedInGroups: Array<ParentGroup>;
};

export const NeurofeedbackSessionGradeConclusion = ({ nfbSessionGrade }: NeurofeedbackSessionGradeConclusionProps) => {
    return (
        <TableContainer component={Paper} sx={{ marginBottom: 2 }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ backgroundColor: "#f5f5f5" }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                                Comentários
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={3}>
                            <Typography variant="h6" color="primary">
                                Justifica a tua avaliação
                            </Typography>
                            <Typography variant="subtitle2">{nfbSessionGrade.technician_notes}</Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={3}>
                            <NeurofeedbackSupervisorNotesGrade notes={nfbSessionGrade.supervisor_notes} nfbSessionGrade={nfbSessionGrade} />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};
