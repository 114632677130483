/**
 * Interface for dropdown choice objects
 */
export interface Choice {
  text: string;
  value: string;
}

/**
 * Gets the display label for a choice value
 * @param choices Array of choice options
 * @param value The value to find the label for
 * @returns The display label or the original value if not found
 */
export function getChoiceLabel(choices: Choice[], value?: string): string {
  if (!value) return "";
  
  const choice = choices.find(choice => choice.value === value);
  return choice?.text || value;
} 