import { useQuery } from "@tanstack/react-query";
import { UseRecordsOptions } from "./useRecords";
import { api } from "./config";
import { checkIfHasUserAndAddUserValues } from "../utils/checkIfHasUserAndAddUserValues";

export const useRecord = (
  entity: string,
  fieldsQuery: string = "*.*",
  itemId: number | string | null = null,
  options?: UseRecordsOptions
) => {
  const recordId = itemId;
  const queryKey = `${entity}-${recordId}`;
  return useQuery({
    queryKey: [queryKey],
    queryFn: () => getRecord(entity, recordId, fieldsQuery, queryKey),
    refetchOnWindowFocus: options?.fetch?.fetchOnFocus,
    enabled: options?.enabled,
  });
};

export const getRecord = async (
  entity: string,
  id: number | string | null | undefined,
  fieldsQuery?: string,
  queryKey?: string
) => {
  if (!id) return { id, entity, queryKey };

  const response = await api({
    method: "GET",
    url: `items/${entity}/${id}?fields=${fieldsQuery}`,
  });

  const item = response.data.data;

  checkIfHasUserAndAddUserValues(item);

  return { item, id, entity, queryKey };
};
