import { DetailHeader } from "../../components/detailHeader/DetailHeader";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { RecordContext } from "../../components/record/Record";
import { useContext } from "react";
import { Actions } from "../../components/actionsMenu/ActionsMenu";

type ProtocolHeaderProps = {
    actions?: Actions;
};

export const ProtocolHeader = ({ actions }: ProtocolHeaderProps) => {
    const recordContext = useContext(RecordContext);

    if (!recordContext) return null;

    const { client } = recordContext.item.treatment;

    return (
        <DetailHeader actions={actions} collection={recordContext.collection}>
            <Grid container alignItems="flex-start" display="flex" flexWrap="nowrap" flexDirection="row" rowGap={5}>
                <Grid minWidth={300} xs={12} sm={6}>
                    <Typography variant="h6" color="secondary">
                        Protocolo de Neurofeedback
                    </Typography>
                    <Typography variant="h5" color="primary">
                        {client?.client_id}
                    </Typography>

                    <Typography variant="body1">
                        {client?.user_id?.first_name} {client?.user_id?.last_name}
                    </Typography>
                </Grid>
            </Grid>
        </DetailHeader>
    );
};
