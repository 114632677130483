import { Box, Typography } from "@mui/material";
import { Warning } from "@mui/icons-material";
import { ProtocolGoalSelection } from "../../../fieldsViews/fields/customized/protocol-goal-selection/ProtocolGoalSelection";

type ProtocolGoalSelectionInputProps = {
    value: any;
};

export const ProtocolGoalSelectionInput = ({ value }: ProtocolGoalSelectionInputProps) => {
    const neurofeedbackGoals = value?.neurofeedback_goal;
    if (neurofeedbackGoals?.length > 0) {
        return <ProtocolGoalSelection />;
    }

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Warning color="warning" />
            <Typography variant="h6">Os objetivos terão que ser criados após o protocolo</Typography>
        </Box>
    );
};
