import { UseRecordsOptions } from "../../api/useRecords";

export const neurofeedbackFeedbackTypesOptions = {
    fieldsToRemove: {
        fieldNamesToRemove: ["id", "content"],
        fieldTypesToRemove: ["alias"],
    },
    groupsOptions: {
        fieldsWithExtraOptions: [],
    },
} as unknown as UseRecordsOptions;
