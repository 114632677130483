import { Controller } from "react-hook-form";
import { FieldProps } from "../renderFieldInput";
import { Select, MenuItem, FormControl, FormHelperText, InputLabel } from "@mui/material";
import { useEffect } from "react";

interface SelectInputFieldProps extends FieldProps {}

export const SelectInputField = ({ field, value, entityForm, options, extraOptions }: SelectInputFieldProps) => {
    let initialValue: any;
 

    if (field.settings.interface === "select-multiple-dropdown") {
        initialValue = value ? value : [];
    } else {
        initialValue = value ? value : "";
    }

    const isRequired = field.settings.required || false;
    
    useEffect(() => {
        if (extraOptions?.defaultValue && !initialValue) {
            entityForm.setValue(field.field, extraOptions?.defaultValue);
        } else {
            const formValue = entityForm.getValues(field.field);
            if (formValue !== initialValue) {
                entityForm.setValue(field.field, initialValue);
            }
        }
    }, [initialValue]);

    return (
        <Controller
            name={extraOptions?.field ? extraOptions?.field : field.field}
            control={entityForm.control}
            defaultValue={initialValue || extraOptions?.defaultValue || ""}
            render={({ field: inputField }) => {
                return (
                    <FormControl fullWidth error={options?.error}>
                        <InputLabel required={isRequired}>{field.headerName}</InputLabel>
                        <Select
                            {...inputField}
                            required={isRequired}
                            disabled={options?.disabled}
                            label={field.headerName}
                            multiple={field.settings.interface === "select-multiple-dropdown"}
                        >
                            <MenuItem value="">Remover {field.headerName}</MenuItem>
                            {field.settings?.options?.choices?.map((choice) => {
                                return (
                                    <MenuItem key={choice.value} value={choice.value}>
                                        {choice.text}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        <FormHelperText>{options?.helperText}</FormHelperText>
                    </FormControl>
                );
            }}
        />
    );
};
