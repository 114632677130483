import { createContext } from "react";
import { Box, LinearProgress } from "@mui/material";
import { DataGrid, DataGridOptions } from "../datagrid/DataGrid";
import { useRecords } from "../../api/useRecords";
import { RecordsHeader } from "../recordsHeader/RecordsHeader";
import { UseRecordsOptions } from "../../api/useRecords";
import { Collection } from "../../types/collections.types";
import { FormatedFields } from "../../types/fields.types";
import { useFields } from "../../api/useFields";
import { useCollection } from "../../api/useCollection";
import { useStore } from "../../store/store";
import { combineFilters } from "../../utils/combineFilters";
import { PaginationModel, Setting } from "../../store/store.types";

type RecordsProps = {
    entity: string;
    fieldsQuery: string;
    body?: React.ReactNode;
    header?: React.ReactNode;
    heightOfHeaders?: number;
    options?: UseRecordsOptions;
};

export type RecordsContextType = {
    entity: string;
    formatedFields: FormatedFields;
    rawFields?: any;
    id?: string | number;
    items?: Array<any>;
    collection: Collection;
    type: "list";
    options?: UseRecordsOptions;
    dataGridOptions?: DataGridOptions;
    metadata?: {
        total_count: number | undefined;
        filter_count: number | undefined;
    };
    apiSettings?: Setting[];
    paginationModel?: PaginationModel;
    refetch?: () => void;
};

export const RecordsContext = createContext<RecordsContextType | null>(null);

export const Records = ({ entity, fieldsQuery, body, header, heightOfHeaders = 180, options }: RecordsProps) => {
    const [recordsFilter, progressFilter, recordsSort, paginationModel] = useStore((state) => {
        return [
            state.recordsFilter || options?.extraOptions?.filter,
            state.progressFilter,
            state.recordsSort,
            state.gridSettings.paginationModel,
        ];
    });

    const { data: collection, status: statusCollection } = useCollection(entity);
    const { formatedFields, rawFields, status: statusFields } = useFields(entity, options);

    const { data, status } = useRecords(entity, fieldsQuery, {
        ...options,
        extraOptions: {
            filter: combineFilters(recordsFilter, progressFilter, entity),
            page: paginationModel.page,
            pageSize: paginationModel.pageSize,
            sort: recordsSort?.[entity]?.sort || options?.extraOptions?.sort,
        },
    });

    if (status === "loading" || statusFields === "loading" || statusCollection === "loading")
        return <LinearProgress color="secondary" />;
    if (status === "error" || statusFields === "error" || statusCollection === "error") return <div>error</div>;

    const recordsContext = {
        ...data,
        collection,
        formatedFields,
        rawFields,
        type: "list" as "list",
        options,
        dataGridOptions: options?.dataGridOptions,
        metadata: {
            total_count: data.metadata?.total_count,
            filter_count: data.metadata?.filter_count,
        },
    };

    return (
        <RecordsContext.Provider value={recordsContext}>
            <Box display="flex" flexDirection="column">
                {header || <RecordsHeader collection={collection} />}
                {body || (
                    <DataGrid
                        paginationModel={paginationModel}
                        heightOfHeaders={heightOfHeaders}
                        type="records"
                        options={options?.dataGridOptions}
                    />
                )}
            </Box>
        </RecordsContext.Provider>
    );
};
