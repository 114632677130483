import { useContext, useEffect, useState } from "react";
import { Autocomplete, ClickAwayListener } from "@mui/material";
import { Controller } from "react-hook-form";
import { FieldProps } from "../../renderFieldInput";
import { useRecords } from "../../../../api/useRecords";
import { handleLabel } from "../../../../utils/handleLabels";
import { useItemsFromSearchParams } from "../../../../api/useItemsFromSearchParams";
import { RecordContext } from "../../../record/Record";
import { handleTypesOfRelatedFields } from "../../../../utils/handleTypesOfRelatedFields";
import { useDebouncedInput } from "../../../../utils/useDebouncedInput";
import {
    ComparedValuesFromInput,
    InputValue,
    renderCustomInput,
    renderCustomOption,
    renderCustomTags,
    useArrangeValuesForApi,
    useCompareInputValuesWithValuesFromApi,
    useHandleInitialValue,
} from "./RelatedInputFieldUtils";
import { handleMultipleRelated } from "./RelatedInputFieldUtils";

export type ValueOfField =
    | {
          name?: string;
          id?: number | string | undefined;
      }
    | any;

interface RelatedInputValueProps extends FieldProps {
    value: ValueOfField;
}

type OptionType = {
    name: string;
    first_name?: string;
    last_name?: string;
    id: number;
    user_id: {
        first_name: string;
        last_name: string;
    };
    client_id?: string;
    template?: string;
    disabled?: boolean;
    status?: string;
};

export const RelatedInputField = ({ field, value, fieldView, entityForm, options, extraOptions }: RelatedInputValueProps) => {
    const [autoCompleteOptions, setAutoCompleteOptions] = useState<InputValue[]>([]);
    const [dependentOnExtraOptions, setDependentOnExtraOptions] = useState<any>(null);
    const [isOpen, setIsOpen] = useState(false);
    const recordContext = useContext(RecordContext);
    const [inputValue, setInputValue] = useState("");
    const [debouncedValue, handleInputChange] = useDebouncedInput("", 3, 500);
    const [isLoading, setIsLoading] = useState(false);
    const [inputFieldsQuery, setInputFieldsQuery] = useState<string>(extraOptions?.fieldsQuery || "*.*");

    const { status, data, refetch } = useRecords(field.settings?.relatedTable || extraOptions?.relatedTable, inputFieldsQuery, {
        enabled: extraOptions?.enabled || false,
        extraOptions: dependentOnExtraOptions || extraOptions,
    });

    const isMultipleRelated =
        field.settings.interface === "list-m2m" || field.settings.type === "manytoany" || extraOptions?.isMultipleRelated;

    const itemFromParams = useItemsFromSearchParams(field.settings.relatedTable || extraOptions.relatedTable);

    const handleInitialValue = useHandleInitialValue(isMultipleRelated, extraOptions, entityForm);
    const compareInputValuesWithValuesFromApi = useCompareInputValuesWithValuesFromApi(field, extraOptions);
    const arrangeValuesForApi = useArrangeValuesForApi(field, extraOptions, recordContext);

    useEffect(() => {
        if (!debouncedValue) return;
        refetch();
    }, [debouncedValue, refetch]);

    // Handle options from api
    useEffect(() => {
        if (status === "success" && data.items.length === 0) setAutoCompleteOptions([]);
        if (status === "success" && data.items.length > 0) {
            const { items } = data;

            if (extraOptions && typeof extraOptions.manipulateResult === "function") {
                const manipulatedItems = extraOptions.manipulateResult(items);
                items.length = 0;
                items.push(...manipulatedItems);
            }

            const arrangedValues = handleTypesOfRelatedFields(field, items, "autoCompleteOptions", field.settings.relatedTable);

            const optionsFromApi = arrangedValues.map((option: OptionType) => {
                if (isMultipleRelated) {
                    const label = handleLabel(
                        option,
                        extraOptions?.displayTemplate || field.settings.display_options?.template || option.template
                    );
                    const id = option.id;

                    return { label: label, id: id, item: option };
                }

                const label = handleLabel(
                    option,
                    extraOptions?.displayTemplate ||
                        field.settings.display_options?.template ||
                        field.settings.display_options?.template
                );
                const id = option.id;

                return { label: label, id: id, item: option };
            });
            const onlyEnabledOptions = optionsFromApi.filter(
                (option) => option.item.disabled !== true && option.item.status !== "suspended"
            );

            setAutoCompleteOptions(onlyEnabledOptions);
        }
    }, [autoCompleteOptions.length, data, extraOptions, status, field, isMultipleRelated]);

    // Handle initial value
    useEffect(() => {
        if (!value && !itemFromParams) return;

        const valuesFromForm = entityForm.getValues(field.field);

        if (valuesFromForm && valuesFromForm.length !== 0 && valuesFromForm.id === itemFromParams?.id) return;

        if (value && value.length !== 0) {
            handleInitialValue(value, field);
        } else if (itemFromParams) {
            if (field.settings.type === "manytoany") {
                const manytoanyItem = {
                    collection: field.settings.relatedTable,
                    id: itemFromParams.id,
                    item: { ...itemFromParams },
                };

                const initialValue = handleInitialValue(manytoanyItem, field);
                const adjustForApi = initialValue?.map((item) => {
                    return { optionId: item.id };
                }) as ComparedValuesFromInput;

                adjustForApi && arrangeValuesForApi(entityForm, adjustForApi, []);
            } else {
                handleInitialValue(itemFromParams, field);
            }
        } else return;
    }, [value, field, entityForm, itemFromParams, handleInitialValue, arrangeValuesForApi]);

    const relatedField = entityForm.getValues(extraOptions?.relatedWith || "");

    // Handle dependent on extra options
    useEffect(() => {
        if (extraOptions && typeof extraOptions.createFilter === "function" && relatedField) {
            const relatedFilter = extraOptions.createFilter(relatedField, extraOptions?.relatedWith);

            const newOptions = {
                ...extraOptions,
                filter: relatedFilter,
            };
            setDependentOnExtraOptions(newOptions);
            return;
        }

        if (extraOptions && typeof extraOptions.createFilter === "function") {
            const relatedFilter = extraOptions.createFilter(inputValue);

            if (relatedFilter?.filter) {
                const newOptions = {
                    ...extraOptions,
                    filter: relatedFilter.filter,
                };

                setDependentOnExtraOptions(newOptions);
                setInputFieldsQuery(relatedFilter.fieldsQuery);
            }
        }
    }, [relatedField, extraOptions, inputValue]);

    return (
        <Controller
            name={extraOptions?.field ? extraOptions?.field : field.field}
            control={entityForm.control}
            defaultValue={isMultipleRelated ? [] : extraOptions?.defaultValue || null}
            render={({ field: inputField }) => {
                return (
                    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                        <Autocomplete
                            {...inputField}
                            disabled={options?.disabled}
                            fullWidth
                            multiple={isMultipleRelated}
                            id={`${field.field}-label`}
                            onOpen={() => {
                                if (
                                    extraOptions?.dependency?.field &&
                                    !entityForm.getValues(extraOptions?.dependency?.field) &&
                                    fieldView !== "filter"
                                ) {
                                    alert(extraOptions?.dependency?.placeHolder);
                                } else {
                                    setIsOpen(true);
                                }
                            }}
                            onClose={() => {
                                setIsOpen(false);
                            }}
                            open={isOpen}
                            onChange={(event: any, newValue: Array<InputValue> | InputValue | null, reason) => {
                                if (extraOptions?.interfereWith && extraOptions?.interfereWithLogic) {
                                    const applyLogic = extraOptions?.interfereWithLogic(newValue, extraOptions, entityForm);

                                    if (applyLogic) {
                                        entityForm.resetField(extraOptions.interfereWith);
                                    }
                                }

                                handleMultipleRelated(
                                    isMultipleRelated,
                                    value,
                                    newValue,
                                    compareInputValuesWithValuesFromApi,
                                    arrangeValuesForApi,
                                    entityForm,
                                    field
                                );

                                inputField.onChange(newValue);
                            }}
                            disablePortal
                            isOptionEqualToValue={(option, value) => {
                                return option.id === value.id;
                            }}
                            onInputChange={(event, newInputValue, reason) => {
                                if (reason === "input") {
                                    setIsLoading(true);
                                    setInputValue(newInputValue); // This is to update your immediate inputValue state
                                    handleInputChange(newInputValue); // This will update the debouncedValue after a delay
                                }
                            }}
                            noOptionsText="Pesquise..."
                            options={autoCompleteOptions || []}
                            loading={isLoading}
                            loadingText={data?.metadata?.filter_count === 0 ? "Sem resultados" : "A pesquisar..."}
                            renderOption={(props, option) => renderCustomOption(props, option)}
                            renderTags={(value, getTagProps) => renderCustomTags(value, getTagProps)}
                            renderInput={(params) => renderCustomInput(params, field, entityForm, options)}
                        />
                    </ClickAwayListener>
                );
            }}
        />
    );
};
