import ReactDOM from "react-dom/client";
import { QueryClient } from "@tanstack/react-query";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { theme } from "./theme";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { App } from "./App";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import * as Sentry from "@sentry/react";

const isDevMode = process.env.NODE_ENV === "development" ? true : false;

if (!isDevMode) {
    Sentry.init({
        dsn: "https://ee103edd5b384d3a80617689ed643279@o4508120533106688.ingest.de.sentry.io/4508131523035216",
        integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Session Replay
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: 1000 * 60 * 60 * 24, // 24 hours
        },
    },
});
const persister = createSyncStoragePersister({
    storage: window.localStorage,
});

const appTheme = createTheme(theme);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <PersistQueryClientProvider
        client={queryClient}
        //@ts-ignore
        persistOptions={{
            persister,
            //@ts-ignore
            buster: isDevMode ? new Date() : __COMMIT_HASH__,
        }}
    >
        <ThemeProvider theme={appTheme}>
            <App />
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
    </PersistQueryClientProvider>
);
