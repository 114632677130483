import { format, parseISO } from "date-fns";
import { useState } from "react";

export const useDateFilter = (initialYear = new Date().getFullYear(), initialMonth = new Date().getMonth()) => {
    const [selectedYear, setSelectedYear] = useState(initialYear);
    const [selectedMonth, setSelectedMonth] = useState(initialMonth);

    const startDate = new Date(selectedYear, selectedMonth, 1);
    const endDate = new Date(selectedYear, selectedMonth + 1, 0);

    return {
        selectedYear,
        setSelectedYear,
        selectedMonth,
        setSelectedMonth,
        startDate,
        endDate,
    };
};

export const formatDate = (dateString: string | null) => (dateString ? format(parseISO(dateString), "dd/MM/yyyy") : null);

export const formatDateWithTime = (dateString: string | null) =>
    dateString ? format(parseISO(dateString), "dd/MM/yyyy HH:mm") : null;
