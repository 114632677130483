import { SchedulesFilters } from "./components/SchedulesFilters";
import { Box } from "@mui/material";
import { SchedulesCalendar } from "./components/SchedulesCalendar";
import { ScheduleWeekTotal } from "./components/schedule-week-totals/ScheduleWeekTotal";
import { useFilteredSchedules, useStaffAvailability, getFilteredResources } from "./utils/scheduleUtils";
import { useStore } from "../../store/store";
import { useDefaultFilters } from "../../components/calendar/calendarUtils";
import { useUser } from "../../api/useAuth";
import { ROLES } from "../../utils/constants";
import { filterUsersRoles } from "../../utils/filterUsersRoles";
import { useUsers } from "../../api/useUsers";
import { useEffect, useState } from "react";
import { CustomEventType } from "./utils/types";
import { useIsMobile } from "../../utils/useIsMobile";
import { useWindowWidth } from "../../utils/useWindowWith";
import { CalendarLoader } from "../../components/calendar/CalendarLoader";
import { useRecords } from "../../api/useRecords";

export const Schedules = () => {
    const { data: user } = useUser();
    const { data: users, status: usersStatus } = useUsers("*.*", filterUsersRoles);
    const isDirectorOrAdmin =
        user?.role.id === ROLES.neurofeedback_director ||
        user?.role.id === ROLES.reception_director ||
        user?.role.id === ROLES.technical_director ||
        user?.role.id === ROLES.admin ||
        user?.role.id === ROLES.shilan;
        
    const defaultFilters = useDefaultFilters();
    const {
        schedules: { calendarFilters, setCalendarFilters, resourceIds },
    } = useStore();

    const [selectMode, setSelectMode] = useState<boolean>(false);
    const [selectedEvents, setSelectedEvents] = useState<Array<CustomEventType>>([]);
    const { data, status } = useRecords("rooms", "*.*", { extraOptions: { sort: "id" } });
    const allResources = data?.items;
    const arrangedResources = getFilteredResources(allResources, resourceIds);

    useEffect(() => {
        if (calendarFilters === null) {
            setCalendarFilters(defaultFilters);
        }
    }, [defaultFilters, setCalendarFilters, calendarFilters]);

    const { events, isLoading: staffLoading, isError } = useStaffAvailability(calendarFilters || defaultFilters);
    const isMobile = useIsMobile();
    const windowWith = useWindowWidth();

    const filteredEvents = useFilteredSchedules(events);
    const isLoading = status === "loading" || staffLoading || usersStatus === "loading";
    if (isError || usersStatus === "error") return <div>error...</div>;

    return (
        <Box style={{ width: isMobile ? windowWith - 16 : windowWith - 280, backgroundColor: "#fff" }}>
            <SchedulesFilters users={users} resources={allResources} />
            {isLoading ? <CalendarLoader /> : null}
            <SchedulesCalendar
                filteredEvents={filteredEvents}
                setSelectedEvents={setSelectedEvents}
                selectMode={selectMode}
                setSelectMode={setSelectMode}
                selectedEvents={selectedEvents}
                arrangedResources={arrangedResources}
                users={users}
            />
            {isDirectorOrAdmin && (
                <ScheduleWeekTotal events={filteredEvents} selectMode={selectMode} setSelectMode={setSelectMode} />
            )}
        </Box>
    );
};
