import { Box, Typography } from "@mui/material";
import { extractArrayFromManyToMany } from "../../utils";

export type FrequencyBand = {
    id: number;
    name: string;
};

type FrequencyBandsProps = {
    frequencyBands: FrequencyBand[];
};
export const FrequencyBands = ({ frequencyBands }: FrequencyBandsProps) => {
    const extractedFrequencyBands = extractArrayFromManyToMany(frequencyBands, "frequency_bands") as FrequencyBand[];
    return (
        <Box>
            {extractedFrequencyBands.map((frequencyBand) => (
                <Typography key={frequencyBand.name} variant="body1">
                    {frequencyBand.name}
                </Typography>
            ))}
        </Box>
    );
};
