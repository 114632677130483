import Box from "@mui/material/Box";
import { generateFeedbackData } from "../../utils";
import { Paper, TableHead, TableRow, Button, Typography, CircularProgress, IconButton } from "@mui/material";
import { Table } from "@mui/material";
import { TableBody } from "@mui/material";
import { TableCell } from "@mui/material";
import { TableContainer } from "@mui/material";
import { NFBfeedback, RowConfig } from "../../types";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useRecords } from "../../../../api/useRecords";
import { Edit, Delete } from "@mui/icons-material";
import { useDeleteRecord } from "../../../../api/useDeleteRecord";
import { AddUpdateDialog } from "../../../../components/add-update-dialogue/AddUpdateDialog";
import { GroupsOptions } from "../../../../types/groups.types";
import { useFieldSelectOptions } from "../../../../utils/useFieldSelectOptions";
type NeurofeedbackFeedbacksProps = {
  nfb_feedback?: NFBfeedback[];
  nfbSessionId: number;
};

const headerStyles = {
  fontWeight: "bold",
  backgroundColor: "#e3f2fd",
  width: 100,
};

const cellStyles = {
  width: 100,
  minWidth: 100,
  maxWidth: 100,
  overflow: "visible",
  wordBreak: "break-word",
  whiteSpace: "normal",
};

const mainHeaderStyles = {
  ...headerStyles,
  textAlign: "center",
  fontSize: "1.1rem",
};

const tableStyles = {
  tableLayout: "fixed",
};

export const nfbFeeddbacksOptions = {
  fieldsWithExtraOptions: [
    {
      field: "neurofeedback_data",
      disabled: true,
    },
    {
      field: "nfb_feedback_type",
      enabled: true,
      filter: {
        status: {
          _eq: "active",
        },
      },
    },
  ],
} as GroupsOptions;

export const NeurofeedbackFeedbacks = ({ nfbSessionId }: NeurofeedbackFeedbacksProps) => {
  const [openDialog, setOpenDialog] = useState({
    isOpen: false,
    type: "create" as "create" | "update",
    id: null as null | number,
  });
  const { data: nfbFeedbacks, status } = useRecords("nfb_feedbacks", "*.*", {
    extraOptions: {
      filter: {
        neurofeedback_data: {
          _eq: nfbSessionId,
        },
      },
      sort: "minute_applied",
    },
  });
  const navigate = useNavigate();
  const deleteRecord = useDeleteRecord();
  const secondaryFeedbackOptions = useFieldSelectOptions("nfb_feedbacks", "nfb_secondary_feedback");

  if (status === "loading") return <CircularProgress />;
  if (status === "error") return <Typography>Error loading feedbacks</Typography>;

  const feedbackData = generateFeedbackData(nfbFeedbacks.items, secondaryFeedbackOptions);

  const rowConfigs = [
    { label: "Minute", key: "minute" },
    { label: "Type", key: "type" },
    { label: "Scale", key: "scale" },
    { label: "Secondary Feedback", key: "secondary_feedback" },
    {
      label: "Information",
      key: "information",
      render: (data) => (
        <Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }}>
          {data.information}
        </Typography>
      ),
    },
    {
      label: "Actions",
      key: "id",
      render: (data) => (
        <Box>
          <IconButton
            onClick={() => {
              navigate(`?[neurofeedback_data][id]=${nfbSessionId}`);
              setOpenDialog({
                isOpen: true,
                type: "update",
                id: data.id,
              });
            }}
          >
            <Edit color="primary" />
          </IconButton>
          <IconButton
            onClick={async () => {
              const confirmed = window.confirm("Tem a certeza que quer apagar este feedback?");
              if (!confirmed) return;
              await deleteRecord.mutateAsync({
                entity: "nfb_feedbacks",
                id: data.id,
              });
            }}
          >
            <Delete color="error" />
          </IconButton>
        </Box>
      ),
    },
  ] as Array<RowConfig>;

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table aria-label="feedback table" size="small" sx={tableStyles}>
          <TableHead>
            <TableRow>
              <TableCell sx={mainHeaderStyles} colSpan={feedbackData.length + 1}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="h6">Feedback</Typography>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      navigate(`?[neurofeedback_data][id]=${nfbSessionId}`);
                      setOpenDialog({
                        isOpen: true,
                        type: "create",
                        id: null,
                      });
                    }}
                  >
                    Adicionar Feedback
                  </Button>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowConfigs.map(({ label, key, render }) => {
              return (
                <TableRow key={key}>
                  <TableCell sx={headerStyles}>{label}</TableCell>
                  {feedbackData.map((data) => {
                    return (
                      <TableCell key={`${key}-${data.id}`} align="center" sx={cellStyles}>
                        {render ? render(data) : data[key]}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {openDialog.isOpen && (
        <AddUpdateDialog
          dialogStatus={openDialog}
          onClose={() => setOpenDialog({ isOpen: false, type: "create", id: null })}
        entity="nfb_feedbacks"
          groupOptions={nfbFeeddbacksOptions}
        />
      )}
    </Box>
  );
};
