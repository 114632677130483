import { Box, TableContainer, Paper, Table, TableHead, TableRow, TableCell, Typography, TableBody } from "@mui/material";
import { NeurofeedbackSessionGradeConclusion } from "./NeurofeedbackSessionGradeConclusion";
import { NFBsessionGradeValues, ParentGroup } from "../../types";

type NeurofeedbackSessionGradeTechSupervisorProps = {
    fieldsOrganizedInGroups: Array<ParentGroup>;
    nfbSessionGrade: NFBsessionGradeValues;
    techAverage: number;
    supAverage: number;
};

export const NeurofeedbackSessionGradeTechSupervisor = ({
    fieldsOrganizedInGroups,
    nfbSessionGrade,
    techAverage,
    supAverage,
}: NeurofeedbackSessionGradeTechSupervisorProps) => {
    return (
        <Box>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                {fieldsOrganizedInGroups.map((group) => {
                    return (
                        <TableContainer key={group.groupField} component={Paper} sx={{ marginBottom: 2 }}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                                        <TableCell>
                                            <Typography variant="subtitle1">{group.title}</Typography>
                                        </TableCell>
                                        <TableCell align="center">Técnico</TableCell>
                                        <TableCell align="center">Supervisor</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {group.subgroups.map((subgroup) => {
                                        const technicianValue = subgroup.fields[0]?.value;
                                        const supervisorValue = subgroup.fields[1]?.value;
                                        return (
                                            <TableRow key={subgroup.groupField} sx={{ height: 54 }}>
                                                <TableCell sx={{ maxWidth: 500 }}>{subgroup.title}</TableCell>
                                                <TableCell align="center">{technicianValue}</TableCell>
                                                <TableCell align="center">{supervisorValue}</TableCell>
                                            </TableRow>
                                        );
                                    })}

                                    <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                                        <TableCell>
                                            <Typography variant="subtitle2">Média</Typography>
                                        </TableCell>
                                        <TableCell align="center">{techAverage.toFixed(2)}</TableCell>
                                        <TableCell align="center">{supAverage.toFixed(2)}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    );
                })}
            </Box>

            <Box>
                <NeurofeedbackSessionGradeConclusion
                    nfbSessionGrade={nfbSessionGrade}
                    fieldsOrganizedInGroups={fieldsOrganizedInGroups}
                />
            </Box>
        </Box>
    );
};
