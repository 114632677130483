import { useContext } from "react";
import { RecordContext } from "../../components/record/Record";
import { Box } from "@mui/material";
import { NeurofeedbackProtocol } from "./components/protocol/NeurofeedbackscoreProtocol";
import { NeurofeedbackSessionGrade } from "./components/session-grade/NeurofeedbacksessionGrade";
import { NeurofeedbackSessionImages } from "./components/NeurofeedbackSessionImages";
import { NeurofeedbackSessionNotes } from "./components/NeurofeedbackSessionNotes";
import { NeurofeedbackFeedbacks } from "./components/neurofeedback-feedback/NeurofeedbackFeedbacks";

export const NeurofeedbackSessionDetail = () => {
    const nfbZScoreSession = useContext(RecordContext);

    if (!nfbZScoreSession) return null;
    const nfbSession = nfbZScoreSession.item;
    const sessionId = nfbSession.id;
    const protocol = nfbSession.protocol;
    const sessionImages = nfbSession.session_printscreens;
    const sessionNotes = nfbSession.notes;
    const calendarSession = nfbSession.session;
    const permanentNote = calendarSession.treatment.permanent_note;

  
    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 8, backgroundColor: "white", padding: 2 }}>
            <NeurofeedbackProtocol protocol={protocol}  />
            <NeurofeedbackFeedbacks nfbSessionId={sessionId} />
            <NeurofeedbackSessionImages sessionImages={sessionImages} nfbSession={nfbSession} />
            <NeurofeedbackSessionNotes sessionNotes={sessionNotes} sessionId={sessionId} permanentNote={permanentNote} />
            <NeurofeedbackSessionGrade sessionId={sessionId} />
        </Box>
    );
};
