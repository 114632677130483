import { useParams } from "react-router-dom";
import { useRecord } from "../api/useRecord";
import { Box, Button, Divider, Paper } from "@mui/material";
import { Process } from "./types";
import { Typography } from "@mui/material";
import { ProcessResponsibleUsers } from "./components/ProcessResponsibleUsers";
import { AccountTree } from "@mui/icons-material";
import { ProcessesBreadcrumbs } from "./components/ProcessesBreadcrumbs";
import { extractUsersFromRoles, processUpdatedAt } from "./utils";
import { ROLES } from "../utils/constants";
import { useUser } from "../api/useAuth";
import { baseUrl } from "../api/config";

export const ProcessDetail = () => {
    const { processid } = useParams();
    const { data, status } = useRecord(
        "processes",
        "*.*,responsible_role.users.*, supervisores_role.directus_roles_id.users.*, responsibles_role.directus_roles_id.users.*, process_category.process_parent_category.*",
        processid
    );
    const { data: currentUser } = useUser();
    const process = data?.item as Process;

    if (status === "loading") return <div>Loading...</div>;
    if (status === "error") return <div>Error</div>;

    const responsibleUsers = extractUsersFromRoles(process.responsibles_role);
    const supervisorUsers = extractUsersFromRoles(process.supervisores_role);

    const isAdmin = currentUser?.role.id === ROLES.admin || currentUser?.role.id === ROLES.shilan;

    return (
        <Paper sx={{ p: 8, flex: 1 }}>
            <Box sx={{ maxWidth: "1200px", mx: "auto", gap: 4 }}>
                <ProcessesBreadcrumbs process={process} />
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography variant="h4" color="secondary">
                        {process.title}
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end", gap: 1 }}>
                        <AccountTree fontSize="large" color="secondary" />
                        <Typography variant="caption">Atualizado: {processUpdatedAt(process)}</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2 }}>
                    <ProcessResponsibleUsers title="Responsáveis" responsibleUsers={responsibleUsers} />
                    {isAdmin && (
                        <Button
                            href={`${baseUrl}/admin/content/processes/${process.id}`}
                            target="_blank"
                            variant="contained"
                            color="primary"
                            size="small"
                        >
                            Editar
                        </Button>
                    )}
                </Box>
                <ProcessResponsibleUsers title="Supervisor" responsibleUsers={supervisorUsers} />
                <Box sx={{ mt: 4 }}>
                    <Divider sx={{ borderColor: "secondary.main", borderWidth: 1 }} />
                    <Box dangerouslySetInnerHTML={{ __html: process.content }} />
                </Box>
            </Box>
        </Paper>
    );
};
