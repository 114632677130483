import { Record } from "../../components/record/Record";
import { Records } from "../../components/records/Records";
import { tabs } from "./protocolTabs";
import { DetailTabs } from "../../components/detailTabs/DetailTabs";
import { EntityForm } from "../../components/entityForm/EntityForm";
import { Groups } from "../../components/groups/Groups";
import { protocolOptions } from "./protocolOptions";
import { ProtocolHeader } from "./ProtocolHeader";

export const nfbProtocolFieldsQuery = [
    "*.*",
    "neurofeedback_goal.*",
    "neurofeedback_goal.frequency_band.*",
    "factors.factors_id.*",
    "electrodes_locations.electrodes_locations_id.*",
    "frequency_bands.frequency_bands_id.*",
    "treatment.client.user_id.*",
    "treatment.client.*",
].join(",");

export const protocolRoutes = [
    {
        path: "/protocols",
        element: <Records entity="protocols" fieldsQuery={nfbProtocolFieldsQuery} options={protocolOptions} />,
    },
    {
        path: "/protocols/:id",
        element: (
            <Record
                entity="protocols"
                type="detail"
                fieldsQuery={nfbProtocolFieldsQuery}
                header={<ProtocolHeader />}
                body={<DetailTabs tabs={tabs} />}
            />
        ),
        children: [
            {
                path: "/protocols/:id/detail",
                element: <Groups groupsOptions={protocolOptions.groupsOptions} />,
            },
        ],
    },
    {
        path: "/protocols/:id/update",
        element: (
            <Record
                entity="protocols"
                fieldsQuery={nfbProtocolFieldsQuery}
                type="update"
                body={<EntityForm groupsOptions={protocolOptions.groupsOptions} />}
            />
        ),
    },
    {
        path: "/protocols/create",
        element: (
            <Record
                entity="protocols"
                fieldsQuery={nfbProtocolFieldsQuery}
                type="create"
                body={<EntityForm groupsOptions={protocolOptions.groupsOptions} />}
            />
        ),
    },
];
