import { UseRecordsOptions } from "../../api/useRecords";

export const protocolOptions = {
    fieldsToRemove: {
        fieldTypesToRemove: ["alias"],
    },
    groupsOptions: {
        fieldsWithExtraOptions: [
            {},
            {
                field: "electrodes_locations",
                relatedTable: "electrodes_locations",
                displayTemplate: "{{name}}",
                sort: "name",
                enabled: true,
            },
            {
                field: "factors",
                relatedTable: "factors",
                displayTemplate: "{{name}}",
                sort: "name",
                enabled: true,
            },

            {
                field: "frequency_bands",
                relatedTable: "frequency_bands",
                displayTemplate: "{{name}}",
                sort: "name",
                enabled: true,
            },
        ],
    },
} as UseRecordsOptions;
