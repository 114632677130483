import { Box } from "@mui/material";
import { useContext, useState } from "react";
import { ToggleNeurofeedbackItems } from "./ToggleNeurofeedbackItems";
import { RecordsRelated } from "../../../../components/recordsRelated/RecordsRelated";
import { fieldsQueryArray } from "../../utils";
import { neuroFeebackDataFilter } from "./utils";
import { RecordContext } from "../../../../components/record/Record";

type NeurofeedbackRelatedProps = {
    heightOfHeaders: number;
};

export type NeurofeedbackView = {
    entity: string;
    fieldsQuery: string;
    filter: any;
    relatedParams?: string;
};

export const nfbDataFieldsQuery = fieldsQueryArray.join(",");

export const NeurofeedbackRelated = ({ heightOfHeaders }: NeurofeedbackRelatedProps) => {
    const parentRecordContext = useContext(RecordContext);
    const treatmentId = parentRecordContext?.item?.id;

    const [neurofeedbackView, setNeurofeedbackView] = useState<NeurofeedbackView>({
        entity: "neurofeedback_data",
        fieldsQuery: nfbDataFieldsQuery,
        filter: neuroFeebackDataFilter,
    });

    return (
        <Box>
            {neurofeedbackView.filter && (
                <RecordsRelated
                    entity={neurofeedbackView.entity}
                    fieldsToQuery={neurofeedbackView.fieldsQuery}
                    customFilter={(params) => neurofeedbackView.filter(params.parentId)}
                    heightOfHeaders={heightOfHeaders}
                    recordsOptions={{ relatedRecords: { hideCreationButton: true } }}
                    customComponentHeaderStyles={{ justifyContent: "flex-start" }}
                    customComponentHeader={
                        <ToggleNeurofeedbackItems
                            setNeurofeedbackView={setNeurofeedbackView}
                            neurofeedbackView={neurofeedbackView}
                            treatmentId={treatmentId}
                        />
                    }
                />
            )}
        </Box>
    );
};
