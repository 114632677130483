import { UseFormReturn } from "react-hook-form";
import { FieldsApiErrors } from "../types/records.types";

export function setFieldsErrors(errors: FieldsApiErrors | any[], entityForm: UseFormReturn) {
    // Return early if errors is not an array
    if (!Array.isArray(errors)) {
        return;
    }

    errors.forEach((error) => {
        // Original format with extensions.field property
        if (error.extensions && error.extensions.field) {
            entityForm.setError(error.extensions.field, { 
                type: error.type || 'error', 
                message: error.message 
            });
            return;
        }
        
        // New format with field name in the error message
        if (error.message && error.message.includes('field "')) {
            const fieldMatch = error.message.match(/field "([^"]+)"/);
            if (fieldMatch && fieldMatch[1]) {
                const fieldName = fieldMatch[1];
                entityForm.setError(fieldName, { 
                    type: 'error', 
                    message: error.message 
                });
            }
        }
    });
}
