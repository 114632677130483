import { Box, Button, LinearProgress, Paper } from "@mui/material";
import { useFields } from "../../../../../api/useFields";
import { findField } from "../../../../../utils/findField";
import { useContext, useState } from "react";
import { RecordContext } from "../../../../record/Record";
import { DialogueGoalSelection } from "./DialogueGoalSelection";
import { GoalField } from "./GoalField";

export type Goal = {
    id: string;
    goal_type: string;
    frequency_band: string;
    protocol: string;
};
export type DialogueState = {
    isOpen: boolean;
    type?: "create" | "edit";
    goal?: Goal;
};

export const ProtocolGoalSelection = () => {
    const recordContext = useContext(RecordContext);
    const item = recordContext?.item;
    const { formatedFields, status: fieldsStatus } = useFields("goals");
    const goalType = findField(formatedFields, "goal_type");
    const frequencyBand = findField(formatedFields, "frequency_band");
    const neurofeedbackGoals = item?.neurofeedback_goal;

    const [dialogueState, setDialogueState] = useState<DialogueState>({
        isOpen: false,
        type: "create",
        goal: undefined,
    });

    if (fieldsStatus === "loading") return <LinearProgress />;
    if (fieldsStatus === "error") return <div>Erro ao carregar os objetivos</div>;

    return (
        <Paper variant="outlined" sx={{ display: "flex", flexDirection: "column", gap: 2, pt: 4, pb: 4, pl: 2, maxWidth: 500 }}>
            {neurofeedbackGoals?.length > 0 &&
                neurofeedbackGoals.map((goal: Goal) => {
                    return (
                        <GoalField
                            key={goal.id}
                            goal={goal}
                            frequencyBand={frequencyBand}
                            goalType={goalType}
                            setDialogueState={setDialogueState}
                        />
                    );
                })}
            <Box>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setDialogueState({ isOpen: true, type: "create", goal: undefined });
                    }}
                >
                    Adicionar Objetivo
                </Button>
            </Box>
            <DialogueGoalSelection
                dialogueState={dialogueState}
                setDialogueState={setDialogueState}
                frequencyBandField={frequencyBand}
                goalTypeField={goalType}
                protocolId={item?.id}
            />
        </Paper>
    );
};
