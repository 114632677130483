import { Calendar as ReactBigCalendar, View } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "../utils/override.css";
import { ScheduleCustomEvent } from "./ScheduleCustomEvent";
import { Box } from "@mui/material";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import {
    handleCalendarDate,
    handleRanges,
    localizer,
    useEventDrop,
    useFormats,
    useHandleSelectSlot,
} from "../../../components/calendar/calendarUtils";
import { useNavigate } from "react-router-dom";
import { set, startOfDay } from "date-fns";
import { useStore } from "../../../store/store";
import { CustomEventType } from "../utils/types";
import { SelectedEventsActions } from "./schedule-actions/SelectedEventsActions";
import { customSlotPropGetter } from "../utils/scheduleUtils";
import { useMemo } from "react";

const minTime = set(new Date(), { hours: 8, minutes: 0, seconds: 0 });
const maxTime = set(new Date(), { hours: 22, minutes: 59, seconds: 59 });

const DnDCalendar = withDragAndDrop(ReactBigCalendar);

// Create a wrapper component to handle props
const EventWrapper = ({ event }: { event: CustomEventType }) => {
    return <ScheduleCustomEvent event={event} />;
};

const CALENDAR_COMPONENTS = {
    event: EventWrapper,
};

type SchedulesCalendarProps = {
    filteredEvents: Array<CustomEventType>;
    selectMode: boolean;
    setSelectMode: (value: boolean) => void;
    setSelectedEvents: (events: Array<CustomEventType>) => void;
    selectedEvents: Array<CustomEventType>;
    arrangedResources: any;
    users: any;
};

export const SchedulesCalendar = ({
    filteredEvents,
    users,
    setSelectedEvents,
    selectMode,
    setSelectMode,
    selectedEvents,
    arrangedResources,
}: SchedulesCalendarProps) => {
    const {
        schedules: { calendarFilters, setCalendarFilters },
    } = useStore();

    const handleSelectSlot = useHandleSelectSlot();
    const navigate = useNavigate();
    const { formats } = useFormats("both");

    const onEventChange = useEventDrop("schedules");

    // Create a unique key that changes when resources change to force re-rendering
    const calendarKey = useMemo(() => {
        return `calendar-${arrangedResources?.length || 0}-${Date.now()}`;
    }, [arrangedResources]);

    const handleEventSelection = (event: CustomEventType) => {
        const result = selectedEvents.some((selectedEvent) => selectedEvent.id === event.id)
            ? selectedEvents.filter((selectedEvent) => selectedEvent.id !== event.id)
            : [...selectedEvents, event];

        setSelectedEvents(result);
    };

    return (
        <Box className="schedules-calendar-wrapper" sx={{ height: 800 }}>
            <DnDCalendar
                key={calendarKey} // Use a dynamic key to force re-render when resources change
                //@ts-ignore
                components={CALENDAR_COMPONENTS}
                resources={arrangedResources}
                //@ts-ignore
                resourceIdAccessor="id"
                //@ts-ignore
                resourceTitleAccessor="title"
                //@ts-ignore
                resourceAccessor="room"
                resourceGroupingLayout={true}
                localizer={localizer}
                culture="pt-PT"
                events={filteredEvents}
                date={calendarFilters?.dateRange && handleCalendarDate(calendarFilters.dateRange)}
                onNavigate={(date) => {}}
                // @ts-ignore
                startAccessor="start"
                // @ts-ignore
                endAccessor="end"
                defaultView="week"
                min={minTime}
                max={maxTime}
                timeslots={4}
                resourcesWidth={10}
                step={15}
                views={["day", "week"]}
                selectable={true}
                onSelectSlot={(e) => {
                    handleSelectSlot({
                        start: e.start,
                        entity: "schedules",
                        defaultMinutes: 240,
                        roomId: e.resourceId as number,
                    });
                }}
                onSelectEvent={(event: any) => {
                    if (selectMode) {
                        handleEventSelection(event);
                        return;
                    }

                    const entity = event.type === "schedule" ? "schedules" : "time_off";
                    navigate(`/${entity}/${event.id}/update`);
                }}
                eventPropGetter={(event: any) => {
                    const isSelected = selectedEvents.some((selectedEvent) => selectedEvent.id === event.id);

                    return {
                        style: {
                            backgroundColor: event?.userColor,
                            opacity: selectMode ? (isSelected ? 1 : 0.7) : 1,
                            border: isSelected ? "2px solid black" : "none",
                        },
                    };
                }}
                // @ts-ignore
                formats={formats}
                slotPropGetter={customSlotPropGetter}
                draggableAccessor={(event) => true}
                onEventDrop={onEventChange}
                resizable
                onEventResize={onEventChange}
                onRangeChange={(range: any, view: View | undefined) => {
                    const newFilters = {
                        ...calendarFilters,
                        dateRange: handleRanges(view === "day" ? [startOfDay(new Date())] : range),
                    };

                    setCalendarFilters(newFilters);
                }}
                dayLayoutAlgorithm="no-overlap"
            />
            <SelectedEventsActions
                selectedEvents={selectedEvents}
                users={users}
                setSelectedEvents={setSelectedEvents}
                setSelectMode={setSelectMode}
                selectMode={selectMode}
                filteredEvents={filteredEvents}
                resources={arrangedResources}
            />
        </Box>
    );
};
