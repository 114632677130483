import axios, { AxiosError } from "axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import store2 from "store2";
import { api, baseUrl } from "./config";
import { Credentials, UserFromAPI } from "../types/auth.types";
import { useNavigate } from "react-router-dom";
import { getRecords } from "./useRecords";
import { getCollections } from "./useCollections";
import { ROLES } from "../utils/constants";

type AuthTokens = {
  access_token: string;
  refresh_token: string;
};

export const useLogin = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate, error, isError } = useMutation<AuthTokens, AxiosError, Credentials>(login, {
    onSuccess: async (authTokens) => {
      const tokens = authTokens;
      store2.set("auth", tokens);

      await queryClient.prefetchQuery({
        queryKey: ["user"],
        queryFn: async () => await getUser(),
      });

      const userData = queryClient.getQueryData<UserFromAPI>(["user"]);

      switch (userData?.role?.id) {
        case ROLES.virtual_assistant:
          navigate("/bank_transactions");
          break;
        case ROLES.accounting:
          navigate("/bank_transactions");
          break;
        case ROLES.tech_people:
          navigate(`/staff-work-logs/${userData?.id}`);
          break;
        default:
          navigate("/sessions");
          break;
      }
    },
  });
  return { mutate, error, isError };
};

export const useUser = () => {
  const auth = store2.get("auth");
  const navigate = useNavigate();

  return useQuery({
    queryKey: ["user"],
    queryFn: async () => {
      if (!auth) {
        navigate("/login");
        return;
      }
      return getUser();
    },
    staleTime: Infinity,
  });
};

export const useUserNotificationPreferences = () => {
  return useQuery({
    queryKey: ["notifications_user_config"],
    queryFn: async () => {
      const collections = await getCollections();
      const collection = collections.find((collection: any) => collection.collection === "notifications_user_config");

      if (!collection) return null;
      const preferences = await getRecords("notifications_user_config", "*.*", {
        staleTime: Infinity,
      });

      return preferences;
    },
  });
};

const login = async (credentials: Credentials): Promise<AuthTokens> => {
  const response = await axios({
    method: "POST",
    url: `${baseUrl}/auth/login`,
    data: {
      email: credentials.email,
      password: credentials.password,
    },
  });

  return {
    access_token: response.data.data.access_token,
    refresh_token: response.data.data.refresh_token,
  };
};

export async function refreshToken(refresh_token: AuthTokens["refresh_token"]) {
  const response = await axios({
    method: "POST",
    url: `${baseUrl}/auth/refresh`,
    data: {
      refresh_token: refresh_token,
    },
  });

  return response.data.data;
}

export async function getUser() {
  const response = await api({
    method: "GET",
    url: `/users/me?fields=*.*`,
  });

  return response.data.data as UserFromAPI;
}

export function decodeJWT(token: string) {
  // Split the token into three parts
  const payload = token.split(".")[1];

  // Base64 decode the payload
  const decodedPayload = JSON.parse(atob(payload));

  // Return the decoded payload
  return decodedPayload;
}
