import { DetailHeader } from "../../components/detailHeader/DetailHeader";
import { Box, IconButton, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import { RecordContext } from "../../components/record/Record";
import { useContext } from "react";
import { Actions } from "../../components/actionsMenu/ActionsMenu";
import { OpenInNew } from "@mui/icons-material";
import { formatDateWithTime } from "../../utils/dateUtils";
import { Avatar } from "../../components/fieldsViews/fields/Avatar";

type EvaluationHeaderProps = {
    actions?: Actions;
};

export const EvaluationHeader = ({ actions }: EvaluationHeaderProps) => {
    const recordContext = useContext(RecordContext);

    if (!recordContext) return null;

    const client = recordContext.item?.treatment?.client;
    const treatment = recordContext.item?.treatment;
    const treatmentLink = `/treatments/${treatment.id}/detail`;
    const session = recordContext.item?.eeg_session;
    const technician = recordContext.item?.eeg_session?.technician?.user_id;

    return (
        <DetailHeader actions={actions} collection={recordContext.collection}>
            <Typography variant="h6" color="secondary">
                Avaliação / EEG
            </Typography>
            <Grid container alignItems="flex-start" display="flex" flexWrap="nowrap" flexDirection="row" gap={5}>
                <Grid  xs={12}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                            variant="h5"
                            color="primary"
                            component={Link}
                            sx={{ textDecoration: "none" }}
                            to={treatmentLink}
                        >
                            {client?.client_id}
                        </Typography>
                        <IconButton sx={{ ml: 2, p: 0 }} color="primary" component={Link} to={treatmentLink} target="_blank">
                            <OpenInNew />
                        </IconButton>
                    </Box>
                    <Typography variant="body1">
                        {client?.user_id?.first_name} {client?.user_id?.last_name}
                    </Typography>
                </Grid>
                <Grid xs={12}>
                    <Typography variant="h6" color="primary">
                        Sessão
                    </Typography>
                    <Typography variant="body1">{formatDateWithTime(session?.start_date)}</Typography>
                </Grid>
                <Grid xs={12}>
                    <Typography variant="h6" color="primary">
                        Técnico EEG
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        <Avatar value={technician?.avatar} styles={{ width: 25, height: 25 }} />
                        <Typography variant="body1">
                            {technician?.first_name} {technician?.last_name}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </DetailHeader>
    );
};
