import { Box } from "@mui/material";
import { useGalleryImages } from "../../../api/useImage";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { FileField } from "../../../components/fieldsViews/fields/FileField";
import { useField } from "../../../api/useField";

type NeurofeedbackSessionImagesProps = {
    sessionImages: SessionImage[];
    nfbSession: any;
};

type SessionImage = {
    directus_files_id: string;
};

export const NeurofeedbackSessionImages = ({ sessionImages, nfbSession }: NeurofeedbackSessionImagesProps) => {
    const extractedImagesIds = sessionImages ? sessionImages.map((image) => image.directus_files_id) : [];
    const { formatedField } = useField("neurofeedback_data", "session_printscreens");
    const { data: images } = useGalleryImages(extractedImagesIds);

    if (!formatedField) return null;

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
            {images && <ImageGallery items={images} showPlayButton={false} showFullscreenButton={false} />}
            <FileField
                value={nfbSession.session_printscreens}
                field={formatedField}
                fieldView="group"
                entity="neurofeedback_data"
                id={nfbSession.id}
                extraOptions={{
                    fileFolder: "deae38ee-f758-430e-83d0-7a88f8f92fc5",
                    field: "session_printscreens",
                    style: {
                        uploadButton: {
                            color: "theme.palette.primary.main",
                            variant: "contained",
                        },
                        fileList: {
                            display: "flex",
                            flexDirection: "row",
                            gap: 2,
                            alignItems: "center",
                            justifyContent: "center",
                        },
                    },
                }}
            />
        </Box>
    );
};
