import { Record } from "../../components/record/Record";
import { Records } from "../../components/records/Records";
import { NeurofeedbackSessionHeader } from "./components/neurofeedback-header/NeurofeedbackSessionHeader";
import { EntityForm } from "../../components/entityForm/EntityForm";
import { NeurofeedbackSessionDetail } from "./NeurofeedbackSessionDetail";
import { fieldsQueryArray } from "./utils";
import { neurofeedbackSessionsOptions } from "./neurofeedbackSessionsOptions";


export const fieldsQuery = fieldsQueryArray.join(",");

export const neurofeedbackSessionRoutes = [
    {
        path: "/neurofeedback_data",
        element: (
            <Records
                entity="neurofeedback_data"
                fieldsQuery={fieldsQuery}
                options={neurofeedbackSessionsOptions}
                heightOfHeaders={190}
            />
        ),
    },

    {
        path: "/neurofeedback_data/:id/detail",
        element: (
            <Record
                entity="neurofeedback_data"
                type="detail"
                fieldsQuery={fieldsQuery}
                header={<NeurofeedbackSessionHeader />}
                body={<NeurofeedbackSessionDetail />}
            />
        ),
    },
    {
        path: "/neurofeedback_data/:id/update",
        element: (
            <Record
                entity="neurofeedback_data"
                type="update"
                fieldsQuery={fieldsQuery}
                header={<NeurofeedbackSessionHeader />}
                body={<EntityForm groupsOptions={neurofeedbackSessionsOptions?.groupsOptions} />}
            />
        ),
    },
    {},
];
