import { Record } from "../../components/record/Record";
import { Records } from "../../components/records/Records";
import { tabs } from "./neurofeedbackFeedbackTypesTabs";
import { EntityForm } from "../../components/entityForm/EntityForm";
import { Groups } from "../../components/groups/Groups";
import { DetailTabs } from "../../components/detailTabs/DetailTabs";
import { neurofeedbackFeedbackTypesOptions } from "./neurofeedbackFeedbackTypesOptions";

export const fieldsQuery = "*.*";

export const neurofeedbackFeedbackTypesRoutes = [
    {
        path: "/nfb_feedback_types",
        element: (
            <Records
                entity="nfb_feedback_types"
                fieldsQuery={fieldsQuery}
                options={neurofeedbackFeedbackTypesOptions}
                heightOfHeaders={190}
            />
        ),
    },
    {
        path: "/nfb_feedback_types/:id",
        element: (
            <Record
                entity="nfb_feedback_types"
                type="detail"
                fieldsQuery={fieldsQuery}
                body={<DetailTabs tabs={tabs} />}
            />
        ),
        children: [
            {
                path: "/nfb_feedback_types/:id/detail",
                element: <Groups />,
            },
        ],
    },
    {
        path: "/nfb_feedback_types/:id/update",
        element: (
            <Record
                entity="nfb_feedback_types"
                type="update"
                body={<EntityForm groupsOptions={neurofeedbackFeedbackTypesOptions?.groupsOptions} />}
            />
        ),
    },
    {
        path: "/nfb_feedback_types/create",
        element: (
            <Record
                entity="nfb_feedback_types"
                type="create"
                body={<EntityForm groupsOptions={neurofeedbackFeedbackTypesOptions?.groupsOptions} />}
            />
        ),
    },
];
