import { Box, Typography } from "@mui/material";
import { formatCurrency } from "../../../utils/formatCurrency";
import { useContext } from "react";
import { StaffWorkLogsContext } from "../StaffWorkLogsDetail";
import { getServiceProviderPriceForDate } from "../utils/calculations";

export const ClientHoursSummary = () => {
    const {
        selectedYear,
        selectedMonth,
        totals: { totalClientHours },
        staffAgreement,
    } = useContext(StaffWorkLogsContext);
    
    // Get the date for the selected month and year
    const date = new Date(selectedYear, selectedMonth);
    
    // Get the correct price values based on the date
    const { valuePerClient } = getServiceProviderPriceForDate(
        staffAgreement?.service_provider_prices,
        date
    );
    
    const totalValue = valuePerClient ? totalClientHours * valuePerClient : null;

    return (
        <Box textAlign="left">
            <Typography variant="h6" color="primary">
                Horas Clientes
            </Typography>
            <Typography>Total horas clientes: {totalClientHours.toFixed(2)}</Typography>
            {totalValue && <Typography>Valor a receber: {formatCurrency(totalValue)}</Typography>}
        </Box>
    );
};
