import { ROLES } from "./constants";

export const filterUsersRoles = {
    _or: [
        {
            _and: [
                {
                    role: {
                        id: {
                            _nin: [
                                ROLES.clients,
                                ROLES.other,
                                ROLES.marketing_bot,
                                ROLES.make
                            ],
                        },
                    },
                },
                {
                    status: {
                        _eq: "active",
                    },
                },
            ],
        },
    ],
};
