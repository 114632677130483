import { GridCellParams } from "@mui/x-data-grid";
import { UseRecordsOptions } from "../../api/useRecords";
import { NeurofeedbackSessionGridField } from "./components/data-grid-fields/NeurofeedbackSessionGridField";
import { NeurofeedbackGradeGridField } from "./components/data-grid-fields/NeurofeedbackGradeGridField";
import { NeurofeedbackProtocolGridField } from "./components/data-grid-fields/NeurofeedbackProtocolGridField";


export const neurofeedbackSessionsOptions = {
    fieldsToRemove: {
        fieldNamesToRemove: ["id", "content"],
        fieldTypesToRemove: ["alias"],
    },

    groupsOptions: {
        fieldsWithExtraOptions: [
            {
                field: "device",
                enabled: true,
            },
            {
                field: "session",
                disabled: true,
            },
            {
                field: "protocol",
                enabled: true,
                createFilter: (params: any) => protocolSearchSettings(params),
                relatedWith: "session",
            },
            {
                field: "nfb_session_grade",
                hidden: {
                    update: true,
                },
            },
            {
                field: "nfb_feedback",
                hidden: {
                    update: true,
                },
            },
        ],
    },
    dataGridOptions: {
        extraColumns: {
            beggining: [
                {
                    field: "session",
                    headerName: "Sessão",
                    width: 75,
                    renderCell: (params: GridCellParams) => {
                        const value = params.row;
                        return <NeurofeedbackSessionGridField value={value} />;
                    },
                },
                {
                    field: "nfb_session_grade",
                    headerName: "Avaliação da sessão",
                    width: 75,
                    renderCell: (params: GridCellParams) => {
                        const value = params.row;
                        return <NeurofeedbackGradeGridField value={value} />;
                    },
                },
                {
                    field: "protocol",
                    headerName: "Protocolo",
                    width: 75,
                    renderCell: (params: GridCellParams) => {
                        const value = params.row;
                        return <NeurofeedbackProtocolGridField value={value} />;
                    },
                },
            ],
            end: [],
        },
    },
} as UseRecordsOptions;

const protocolSearchSettings = (params: any) => {
    var filter = {};

    const sessionId = params.id;
    if (sessionId) {
        filter = {
            treatment: {
                sessions: {
                    id: {
                        _eq: sessionId,
                    },
                },
            },
        };
    }

    return filter;
};

