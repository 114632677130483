import { Box, Button, CircularProgress, Paper, Typography } from "@mui/material";
import { NFBsessionGradeValues } from "../../types";
import { useState } from "react";
import { AddUpdateDialog } from "../../../../components/add-update-dialogue/AddUpdateDialog";
import { GroupsOptions } from "../../../../types/groups.types";
import { useRecords } from "../../../../api/useRecords";
import { NeurofeedbackSessionGradeTechSupervisor } from "./NeurofeedbackSessionGradeTechSupervisor";
import { useNavigate } from "react-router-dom";
import { useSessionGrade } from "../../utils";
import { NeurofeedbackGlobalGradeChip } from "./NeurofeedbackGlobalGradeChip";

const nfbSessionGradeOptions = {
    fieldsToRemove: {
        fieldNamesToRemove: [
            "brain_modulation",
            "technical_part",
            "technician_artificats_impedence_question",
            "technician_feedback_and_thresholds_question",
            "technician_guide_client_question",
        ],
    },
    fieldsWithExtraOptions: [
        {
            field: "neurofeedback_data",
            disabled: true,
        },
    ],
} as GroupsOptions;

type NeurofeedbackSessionGradeProps = {
    sessionId: number;
};

export const NeurofeedbackSessionGrade = ({ sessionId }: NeurofeedbackSessionGradeProps) => {
    const { data: grades, status: gradesStatus } = useRecords("nfb_session_grades", "*.*", {
        extraOptions: {
            filter: {
                neurofeedback_data: {
                    _eq: sessionId,
                },
            },
        },
    });
    const [openDialog, setOpenDialog] = useState({
        isOpen: false,
        type: "create" as "create" | "update",
        id: null as null | number,
    });
    const navigate = useNavigate();
    const nfbSessionGrade = grades?.items[0] as NFBsessionGradeValues;
    const gradeId = nfbSessionGrade?.id;

    const { sessionGlobalGrade, fieldsOrganizedInGroups, techAverage, supAverage } = useSessionGrade(nfbSessionGrade);

    if (gradesStatus === "loading") return <CircularProgress />;
    if (gradesStatus === "error") return <h1>Error</h1>;

    return (
        <Paper sx={{ width: "100%", padding: 2, display: "flex", flexDirection: "column", gap: 4 }}>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                    <Typography variant="h5" gutterBottom>
                        Análise da Sessão de Neurofeedback:
                    </Typography>
                    <NeurofeedbackGlobalGradeChip grade={sessionGlobalGrade} />
                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        if (!gradeId) {
                            navigate(`?[neurofeedback_data][id]=${sessionId}`);
                        }
                        setOpenDialog({
                            isOpen: true,
                            type: gradeId ? "update" : "create",
                            id: gradeId ? gradeId : null,
                        });
                    }}
                >
                    {gradeId ? "Atualizar Avaliação de sessão" : "Adicionar Avaliação de sessão"}
                </Button>
            </Box>
            {nfbSessionGrade && (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
                    <Paper sx={{ backgroundColor: "#f5f5f5", padding: 2, display: "flex", justifyContent: "space-between" }}>
                        <Box sx={{ display: "flex", alignItems: "baseline", gap: 4 }}>
                            <Typography variant="h5" color="primary">
                                Colaboração do cliente:
                            </Typography>
                            <Typography variant="h5">{nfbSessionGrade.client_collaboration}</Typography>
                        </Box>
                    </Paper>
                    <NeurofeedbackSessionGradeTechSupervisor
                        fieldsOrganizedInGroups={fieldsOrganizedInGroups}
                        nfbSessionGrade={nfbSessionGrade}
                        techAverage={techAverage ?? 0}
                        supAverage={supAverage ?? 0}
                    />
                </Box>
            )}
            {openDialog.isOpen && (
            <AddUpdateDialog
                dialogStatus={openDialog}
                    maxWidth="xl"
                    onClose={() => setOpenDialog({ isOpen: false, type: "create", id: null })}
                    entity="nfb_session_grades"
                    groupOptions={nfbSessionGradeOptions}
                />
            )}
        </Paper>
    );
};
