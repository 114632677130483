import { useContext, useEffect } from "react";
import store2 from "store2";
import useWebSocket from "react-use-websocket";
import { useBrowserNotification } from "../sockets/useBrowserNotification";
import { CollectionsContext } from "../components/root/CollectionsProvider";
import { Collection } from "../types/collections.types";
import { useUpdatePhoneClient } from "../sockets/useUpdatePhoneClient";
import { useLogoutFromDevice } from "../sockets/useLogoutFromDevice";

const env = process.env.NODE_ENV;
const socketUrl =
  env === "development" ? "wss://backend.neuroimprove.pt/websocket" : "wss://backend.neuroimprove.pt/websocket";
export const useWebSockets = () => {
  const { createLeadNotification, showNotification } = useBrowserNotification();
  const updatePhoneClient = useUpdatePhoneClient();
  const logOutFromDevice = useLogoutFromDevice();
  const collections = useContext(CollectionsContext);

  const { sendMessage, lastMessage } = useWebSocket(socketUrl, {
    shouldReconnect: (closeEvent) => true, // Always attempt to reconnect
    reconnectAttempts: 10,
    reconnectInterval: 5000,
    onOpen: () => {

      const access_token = store2.get("auth")?.access_token;

      // Authenticate upon opening the connection
      sendMessage(
        JSON.stringify({
          type: "auth",
          access_token,
        })
      );
    },
    onError: (error) => console.error("WebSocket Error:", error),
    onClose: (event) => console.log("Disconnected:", event.code, event.reason),
  });


  const createSubscription = async (collectionToSubscribe: string, fields: string[], collections: Collection[]) => {
    const collectionExists = collections.find((collection) => collection.collection === collectionToSubscribe);

    if (collectionExists) {
      sendMessage(
        JSON.stringify({
          type: "subscribe",
          collection: collectionToSubscribe,
          query: { fields },
          uid: collectionToSubscribe,
        })
      );
      console.log("websocket subscribed to=>", collectionToSubscribe);
    }
  };

  useEffect(() => {
    if (lastMessage !== null) {
      const data = JSON.parse(lastMessage.data);

      if (data.type === "auth" && data.status === "ok") {
        if (!collections) return;
        createSubscription("leads", ["*.*"], collections.collections);
        createSubscription("call_center_licenses", ["*.*"], collections.collections);
        createSubscription("onpremises_devices_usage", ["*.*"], collections.collections);
      }

      if (data.type === "subscription") {
        // Handle different collections subscriptions
        if (data.uid === "leads" || data.uid === "call_center_licenses") {
          createLeadNotification(data);
          updatePhoneClient(data);
        } else if (data.uid === "onpremises_devices_usage") {
          logOutFromDevice(data);
        }
      }

      if (data.type === "ping") {
        sendMessage(JSON.stringify({ type: "pong" }));
      }

      if (data.status === "error") {
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage, collections]);
};

export type SubscriptionData = {
  type: "subscription";
  event: "create" | "update" | "delete";
  uid: string;
  data: any;
};
