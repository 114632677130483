import { Box, CircularProgress, Typography } from "@mui/material";
import { formatCurrency } from "../../../utils/formatCurrency";
import { useContext } from "react";
import { StaffWorkLogsContext } from "../StaffWorkLogsDetail";
import { useWorkedVsExpectedHours } from "../utils/useWorkVsExpectedHours";
import { getServiceProviderPriceForDate } from "../utils/calculations";

type WorkHoursSummaryProps = {
    isAdminOrCurrentUser: boolean;
    isServiceProvider: boolean;
};

export const WorkHoursSummary = ({ isAdminOrCurrentUser, isServiceProvider }: WorkHoursSummaryProps) => {
    const {
        selectedYear,
        selectedMonth,
        totals: { effectiveWorkHours, totalWorkHours, totalHolidays, totalSickLeaveDays },
        staffAgreement,
        userId,
    } = useContext(StaffWorkLogsContext);

    // Get the date for the selected month and year
    const date = new Date(selectedYear, selectedMonth);
    
    // Get the correct price values based on the date
    const { valuePerHour } = getServiceProviderPriceForDate(
        staffAgreement?.service_provider_prices,
        date
    );

    const totalValue = valuePerHour ? effectiveWorkHours * valuePerHour : null;

    const convertedHolidaysAndSickLeaveDaysHours = (totalHolidays + totalSickLeaveDays) * 8;
    const { data, status: expectedWorkingHoursStatus } = useWorkedVsExpectedHours(
        date,
        userId,
        convertedHolidaysAndSickLeaveDaysHours
    );
    if (expectedWorkingHoursStatus === "loading") return <CircularProgress />;

    // Im including the holidays in the total worked hours so we can see if we are passing max amount of hours per month
    const totalWorkHoursWithUnvavailabilities = totalWorkHours + convertedHolidaysAndSickLeaveDaysHours;

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Box>
                <Typography variant="h6" color="primary">
                    Horas trabalhadas Mês
                </Typography>
                <Box sx={{ display: "flex", gap: 1 }}>
                    <Typography variant="body1">
                        {isServiceProvider ? effectiveWorkHours.toFixed(2) : totalWorkHoursWithUnvavailabilities.toFixed(2)}{" "}
                        {!isServiceProvider && `/ ${data?.monthlyExpectedHours.toFixed(2)}`}
                    </Typography>
                    {isAdminOrCurrentUser && isServiceProvider && (
                        <Typography>Valor a receber: {totalValue && formatCurrency(totalValue)}</Typography>
                    )}
                </Box>
            </Box>
            <Box>
                <Typography variant="h6" color="primary">
                    Horas trabalhadas Ano
                </Typography>
                <Box sx={{ display: "flex", gap: 1 }}>
                    <Typography variant="body1">
                        {data?.hoursWorkedUpToCurrentMonth.toFixed(2)}{" "}
                        {!isServiceProvider && `/ ${data?.hoursExpectedUpToCurrentMonth.toFixed(2)}`}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};
