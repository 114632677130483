import { Box, Typography } from "@mui/material";
import { Avatar } from "../../../../components/fieldsViews/fields/Avatar";
import { formatDateWithTime } from "../../../../utils/dateUtils";

type NeurofeedbackSessionGridFieldProps = {
    value: any;
};

export const NeurofeedbackSessionGridField = ({ value }: NeurofeedbackSessionGridFieldProps) => {
    const technician = value?.session?.technician?.user_id;
    const sessionStartDate = value?.session?.start_date;

    return (
        <Box sx={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <Box sx={{ width: 150 }}>
                <Typography variant="body1">{sessionStartDate && formatDateWithTime(sessionStartDate)}</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Avatar value={technician?.avatar} styles={{ width: 25, height: 25 }} />
                <Typography variant="body1">
                    {technician?.first_name} {technician?.last_name}
                </Typography>
            </Box>
        </Box>
    );
};
