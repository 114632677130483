import {
    Divider,
    Unstable_Grid2 as Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from "@mui/material";
import { renderFieldView } from "../fieldsViews/renderFieldView";
import { renderFieldInput } from "../fieldsInputs/renderFieldInput";
import { Groups as GroupsType, formatGroups } from "../../utils/formatGroups";
import { useTheme } from "@mui/material/styles";
import { UseFormReturn } from "react-hook-form";
import { useContext } from "react";
import { RecordContext } from "../record/Record";
import { GroupsOptions } from "../../types/groups.types";
import { FormatedFields } from "../../types/fields.types";
import { getFieldExtraOption } from "../../utils/getFieldExtraOption";

type GroupsProps = {
    item?: any;
    fields?: FormatedFields;
    entityForm?: UseFormReturn;
    type?: string;
    groupsOptions?: GroupsOptions;
};

export function Groups({ item, fields, entityForm, type = "detail", groupsOptions }: GroupsProps) {
    const context = useContext(RecordContext);
    const theme = useTheme();

    if (!item && !fields && context) {
        item = context.item;
        fields = context.formatedFields;
    }

    if (!fields) return null;

    const { groups } = formatGroups(fields, item, type);

    return (
        <Grid container justifyContent="flex-start" alignItems="top" display="flex" sx={{ backgroundColor: "#fff" }}>
            {groups?.map((group) => {
                return (
                    <Grid key={group.groupId} p={4} xs={12} flex={1} lg={groupColumnSize(groups)} minWidth={300} >
                        <Typography
                            color="primary"
                            fontWeight="bold"
                            variant="subtitle1"
                            sx={{
                                textTransform: "capitalize",
                            }}
                            align="left"
                        >
                            {group.groupName}
                        </Typography>
                        <Divider
                            sx={{
                                paddingLeft: 0,
                                borderBottom: `2px solid ${theme.palette.primary.main}`,
                                marginTop: 2,
                                marginBottom: 4,
                            }}
                        />
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    {group?.fields.map((field) => {
                                        const extraOptions = groupsOptions?.fieldsWithExtraOptions;

                                        const fieldExtraOptions = extraOptions ? getFieldExtraOption(extraOptions, field) : null;

                                        if (field.settings.hidden) return null;
                                        return (
                                            <TableRow key={field.field}>
                                                <TableCell
                                                    sx={{
                                                        maxWidth: 400,
                                                        border: "0px",
                                                        paddingLeft: 0,
                                                        paddingTop: 2,

                                                    }}
                                                    align="left"
                                                >
                                                    {type === "detail" && !fieldExtraOptions?.hidden?.detail ? (
                                                        <>
                                                            <Typography
                                                                sx={{
                                                                    color: "primary.main",
                                                                }}
                                                                variant="subtitle1"
                                                            >
                                                                {field.headerName}
                                                            </Typography>
                                                            {renderFieldView(field, item, "group", fieldExtraOptions)}
                                                        </>
                                                    ) : type === "update" ? (
                                                        entityForm &&
                                                        renderFieldInput(field, item, "group", entityForm, fieldExtraOptions)
                                                    ) : (
                                                        entityForm &&
                                                        renderFieldInput(field, null, "group", entityForm, fieldExtraOptions)
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                );
            })}
        </Grid>
    );
}

const groupColumnSize = (groups: GroupsType) => {
    if (groups.length === 1) return 12;
    if (groups.length === 2) return 6;
    if (groups.length > 2) return 4;
};
