import { Record } from "../../components/record/Record";
import { Records } from "../../components/records/Records";
import { ClientHeader } from "./ClientHeader";
import { tabs } from "./clientTabs";
import { DetailTabs } from "../../components/detailTabs/DetailTabs";
import { EntityForm } from "../../components/entityForm/EntityForm";
import { Groups } from "../../components/groups/Groups";
import { RecordsRelated } from "../../components/recordsRelated/RecordsRelated";
import { clientsOptions } from "./clientOptions";
import { treatmentsOptions } from "../treatments/treatmentOptions";
import { relativesOptions } from "../relatives/relativesOptions";
import { NotesComponent } from "../../components/notes/NotesComponent";
import { ClientListHeader } from "./ClientListHeader";
import { recordsRelatedCustomFilter } from "../../utils/recordsRelatedCustomFilter";
import { tasksOptions } from "../tasks/tasksOptions";

const fieldsQuery = "*.*,client.user_id.*,diagnose_secondary_multi.disorders_id.*";

export const clientRoutes = [
    {
        path: "/clients",
        element: (
            <Records
                entity="clients"
                fieldsQuery={fieldsQuery}
                options={clientsOptions}
                header={<ClientListHeader />}
                heightOfHeaders={210}
            />
        ),
    },
    {
        path: "/clients/:id",
        element: (
            <Record
                entity="clients"
                type="detail"
                header={<ClientHeader />}
                fieldsQuery={fieldsQuery}
                body={<DetailTabs tabs={tabs} />}
            />
        ),
        children: [
            {
                path: "/clients/:id/detail",
                element: <Groups groupsOptions={clientsOptions.groupsOptions} />,
            },
            {
                element: (
                    <RecordsRelated
                        entity="treatments"
                        fieldsToQuery="*.*,client.user_id.*,client_owner.user_id.*,psychologist.user_id.*,client_manager.user_id.*, theurapists_involved_in_treamtent.technicians_id.user_id.*"
                        fieldToFilter="client"
                        recordsOptions={treatmentsOptions}
                    />
                ),
                path: "/clients/:id/treatments",
            },
            {
                element: (
                    <RecordsRelated
                        entity="relatives"
                        fieldsToQuery="*.*,client.user_id.*"
                        fieldToFilter="client_id"
                        recordsOptions={relativesOptions}
                    />
                ),
                path: "/clients/:id/relatives",
            },
            {
                element: <NotesComponent extraEntities={["leads"]} />,
                path: "/clients/:id/notes",
            },
            {
                element: (
                    <RecordsRelated
                        entity="tasks"
                        fieldsToQuery="*.*,assigned_users.directus_users_id.*, related_with.item:leads.*, related_with.item:clients.*,related_with.item:clients.user_id.*, related_with.item:treatments.client.client_id,status, comments.user_created.*"
                        customFilter={recordsRelatedCustomFilter}
                        recordsOptions={tasksOptions}
                    />
                ),
                path: "/clients/:id/tasks",
            },
        ],
    },
    {
        path: "/clients/:id/update",
        element: (
            <Record
                entity="clients"
                type="update"
                fieldsQuery="*.*,diagnose_secondary_multi.disorders_id.*"
                header={<ClientHeader />}
                body={<EntityForm groupsOptions={clientsOptions?.groupsOptions} />}
            />
        ),
    },
    {
        path: "/clients/create",
        element: (
            <Record
                entity="clients"
                type="create"
                fieldsQuery="*.*,diagnose_secondary_multi.disorders_id.*"
                body={<EntityForm groupsOptions={clientsOptions?.groupsOptions} />}
            />
        ),
    },
];
