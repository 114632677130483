import { List, ListItemText, ListItemButton, Typography, Paper, LinearProgress, Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useRecords } from "../api/useRecords";
import { ProcessCategory } from "./types";
import { ProcessesTypeIcon } from "./components/ProcessesTypeIcon";
import { processStyles } from "./processStyles";

export const ProcessesCategoriesList = () => {
    const { parentcategoryid } = useParams();
    const navigate = useNavigate();
    const { data, status } = useRecords("process_category", "*.*", {
        extraOptions: {
            filter: {
                process_parent_category: {
                    id: {
                        _eq: parentcategoryid,
                    },
                },
            },
        },
    });

    if (status === "loading") return <LinearProgress />;
    if (status === "error") return <Typography variant="h4">Erro ao carregar os processos</Typography>;

    return (
        <Paper sx={{ p: 3, height: "100vh" }}>
            {data?.items.length === 0 ? (
                <Typography variant="h4">Ainda sem processos</Typography>
            ) : (
                <>
                    <Typography variant="h4" mb={4}>
                        {data?.items[0].process_parent_category?.name}
                    </Typography>
                    <List>
                        {data?.items.map((item: ProcessCategory) => (
                            <ListItemButton
                                key={item.id}
                                sx={processStyles.listItem}
                                onClick={() => navigate(`/processes/parentcategory/${parentcategoryid}/category/${item.id}`)}
                            >
                                <ListItemText primary={item.name} />
                                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                    {Array.from(new Set(item.processes.map((process) => process?.type))).map((type) => (
                                        <ProcessesTypeIcon key={type} type={type} />
                                    ))}
                                </Box>
                            </ListItemButton>
                        ))}
                    </List>
                </>
            )}
        </Paper>
    );
};
