import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { renderFieldInput } from "../../../../fieldsInputs/renderFieldInput";
import { useForm } from "react-hook-form";
import { useCreateRecord } from "../../../../../api/useCreateRecord";
import { useUpdateRecord } from "../../../../../api/useUpdateRecord";
import { DialogueState } from "./ProtocolGoalSelection";
import { FormatedField } from "../../../../../types/fields.types";
import { useQueryClient } from "@tanstack/react-query";
import { useStore } from "../../../../../store/store";

type DialogueGoalSelectionProps = {
    dialogueState: DialogueState;
    setDialogueState: (state: DialogueState) => void;
    protocolId: string;
    goalTypeField: FormatedField;
    frequencyBandField: FormatedField;
};

export const DialogueGoalSelection = ({
    dialogueState,
    setDialogueState,
    protocolId,
    goalTypeField,
    frequencyBandField,
}: DialogueGoalSelectionProps) => {
    const createGoal = useCreateRecord();
    const updateGoal = useUpdateRecord();
    const queryClient = useQueryClient();
    const { notifications } = useStore();
    const currentGoal = dialogueState.goal;
    const entityForm = useForm();

    const onSubmit = async (data: any) => {
        const payload = {
            protocol: protocolId,
            frequency_band: data.frequency_band.id,
            goal_type: data.goal_type,
        };

        try {
            if (dialogueState.type === "create") {
                await createGoal.mutateAsync({
                    data: payload,
                    entity: "goals",
                });
                setDialogueState({ isOpen: false, type: "create" });
            } else {
                await updateGoal.mutateAsync({
                    data: payload,
                    entity: "goals",
                    id: currentGoal?.id,
                });
                setDialogueState({ isOpen: false, type: "edit" });
            }
        } catch (error) {
            notifications.setNotification({
                message: "Erro ao salvar o objetivo",
                severity: "error",
                isOpen: true,
            });
        } finally {
            notifications.setNotification({
                message: "Objetivo salvo com sucesso",
                severity: "success",
                isOpen: true,
            });
            queryClient.invalidateQueries({ queryKey: [`protocols-${protocolId}`] });
            entityForm.reset();
        }
    };

    const handleFormSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        entityForm.handleSubmit(onSubmit)(e);
    };

    return (
        <Dialog
            open={dialogueState.isOpen}
            onClose={() => {
                entityForm.reset();
                setDialogueState({ isOpen: false });
            }}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>Adicionar Objetivo</DialogTitle>
            <Box component="form" id="goal-form">
                <DialogContent sx={{ minHeight: 400 }}>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
                        <Box>{renderFieldInput(goalTypeField, currentGoal, "group", entityForm)}</Box>
                        <Box>
                            {renderFieldInput(frequencyBandField, currentGoal, "group", entityForm, {
                                enabled: true,
                                sort: "name",
                            })}
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            entityForm.reset();
                            setDialogueState({ isOpen: false });
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button variant="contained" color="primary" type="button" onClick={handleFormSubmit}>
                        Guardar
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
};
