import { Box, Typography } from "@mui/material";
import { extractArrayFromManyToMany } from "../../utils";

export type ElectrodesLocation = {
    name: string;
};

type ElectrodeLocationsProps = {
    electrodesLocations: ElectrodesLocation[];
};

export const ElectrodeLocations = ({ electrodesLocations }: ElectrodeLocationsProps) => {
    const extractedElectrodeLocations = extractArrayFromManyToMany(
        electrodesLocations,
        "electrodes_locations"
    ) as ElectrodesLocation[];

    return (
        <Box sx={{ display: "flex", flexDirection: "row", gap: 1, mb: 2 }}>
            {extractedElectrodeLocations.map((location) => (
                <Typography key={location.name} variant="body1">
                    {location.name}
                </Typography>
            ))}
        </Box>
    );
};
