import { useNavigate, useParams } from "react-router-dom";
import { useRecords } from "../api/useRecords";
import { LinearProgress, List, ListItemButton, ListItemIcon, ListItemText, Paper, Typography } from "@mui/material";
import { Process } from "./types";
import { ProcessesTypeIcon } from "./components/ProcessesTypeIcon";
import { processStyles } from "./processStyles";
import { processUpdatedAt } from "./utils";

export const ProcessesList = () => {
    const { parentcategoryid, categoryid } = useParams();

    const navigate = useNavigate();
    const { data, status } = useRecords("processes", "*.*", {
        extraOptions: {
            filter: {
                _and: [
                    {
                        process_category: {
                            id: {
                                _eq: categoryid,
                            },
                        },
                        status: {
                            _eq: "published",
                        },
                    },
                ],
            },
            sort: "sort",
        },
    });

    if (status === "loading") return <LinearProgress />;
    if (status === "error") return <Typography variant="h4">Erro ao carregar os processos</Typography>;

    return (
        <Paper sx={{ p: 3, height: "100vh" }}>
            {data?.items.length === 0 ? (
                <Typography variant="h4">Ainda sem processos</Typography>
            ) : (
                <>
                    <Typography variant="h4" mb={4}>
                        {data?.items[0].process_category?.name}
                    </Typography>
                    <List>
                        {data?.items.map((item: Process) => (
                            <ListItemButton
                                key={item.id}
                                sx={processStyles.listItem}
                                onClick={() =>
                                    navigate(`/processes/parentcategory/${parentcategoryid}/category/${categoryid}/${item.id}`)
                                }
                            >
                                <ListItemText primary={item.title} secondary={processUpdatedAt(item)} />

                                <ListItemIcon>
                                    <ProcessesTypeIcon type={item?.type} />
                                </ListItemIcon>
                            </ListItemButton>
                        ))}
                    </List>
                </>
            )}
        </Paper>
    );
};
