import store2 from "store2";
import axios from "axios";
import { refreshToken } from "./useAuth";
import qs from "qs";

const env = process.env.NODE_ENV;

export const baseUrl = env === "development" ? "https://backend.neuroimprove.pt" : "https://backend.neuroimprove.pt";

const api = axios.create({
    paramsSerializer: {
        serialize: (params) => {
            return qs.stringify(params, { encode: false });
        },
    },
    baseURL: baseUrl,
});

api.interceptors.request.use(
    (config) => {
        const tokens = store2.get("auth");

        if (!tokens) {
            window.location.replace("/login");
            return config;
        }

        config.headers.set("Authorization", `Bearer ${tokens.access_token}`);
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err?.config;
        const retryOrigConfig = await handleAuthError(err, originalConfig);

        if (retryOrigConfig) {
            return retryOrigConfig;
        }

        return Promise.reject(err);
    }
);

export { api };

let refreshPromise: Promise<any> | null = null;

//@ts-ignore
async function handleAuthError(err, originalConfig) {
    if (err.response?.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true; // Mark this request as retried

        if (!refreshPromise) {
            const refresh_token = store2.get("auth")?.refresh_token;

            refreshPromise = refreshToken(refresh_token)
                .then((updatedTokens) => {
                    store2.set("auth", updatedTokens);
                    refreshPromise = null;
                    return updatedTokens;
                })
                .catch((error) => {
                    refreshPromise = null;
                    store2.remove("auth");
                    document.location.href = "/login"; // Redirect to login on refresh failure
                });
        }

        const newToken = await refreshPromise; // Wait for the refresh to complete
        originalConfig.headers["Authorization"] = `Bearer ${newToken.access_token}`; // Update the token
        return api(originalConfig); // Retry the original request
    }

    return null;
}


