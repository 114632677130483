import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import logo from "../../public/neuroimprove_fav.png";
import { useBrowserNotification } from "../sockets/useBrowserNotification";

export const Sandbox = () => {
    const { showNotification } = useBrowserNotification();
//   const [permission, setPermission] = useState<NotificationPermission>(
//     "Notification" in window ? Notification.permission : "denied"
//   );
//   const [swRegistration, setSwRegistration] = useState<ServiceWorkerRegistration | null>(null);
//   const isSecureContext = window.isSecureContext;

//   useEffect(() => {
//     // Register service worker
//     if ("serviceWorker" in navigator && isSecureContext) {
//       navigator.serviceWorker
//         .register("/notification-worker.js")
//         .then((registration) => {
//           console.log("ServiceWorker registration successful");
//           setSwRegistration(registration);
//         })
//         .catch((err) => {
//           console.log("ServiceWorker registration failed:", err);
//         });
//     }
//   }, [isSecureContext]);

//   const handleNotificationClick = async () => {
//     console.log("Button clicked");
//     console.log("Protocol:", window.location.protocol);
//     console.log("Secure Context:", isSecureContext);
//     console.log("Current permission:", Notification.permission);

//     if (!isSecureContext) {
//       alert("Notifications require a secure context (HTTPS or localhost). Current context is not secure.");
//       return;
//     }

//     try {
//       // Try direct notification first for development
//       if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
//         const notification = new Notification("Direct Test", {
//           body: "Testing direct notification on localhost",

//           icon: logo,
//           silent: false,
//         });
//         console.log("Direct notification sent");
//         return;
//       }

//       // Fall back to ServiceWorker for production
//       if (!swRegistration) {
//         console.log("No ServiceWorker registration available");
//         return;
//       }

//       await swRegistration.showNotification("Chrome Test", {
//         body: "Testing notification with ServiceWorker",
//         requireInteraction: false,
//         silent: false,
//       });

//       console.log("Notification requested through ServiceWorker");
//     } catch (error) {
//       console.error("Notification error:", error);
//       alert(`Error showing notification: ${error}`);
//     }
//   };

  return (
    <div style={{ padding: 20 }}>
        <Button variant="contained" onClick={() => showNotification({ title: "Test", body: "Test", itemPath: "test" })} style={{ marginBottom: 10 }}>
            Test Notification
        </Button>
      {/* <Button variant="contained" onClick={handleNotificationClick} style={{ marginBottom: 10 }}>
        Test Chrome Notification
      </Button> */}
      {/* <div>Current Permission: {permission}</div> */}
      {/* <div>Protocol: {window.location.protocol}</div>
      <div>Secure Context: {isSecureContext ? "Yes" : "No"}</div>
      <div>ServiceWorker Available: {"serviceWorker" in navigator ? "Yes" : "No"}</div>
      <div>ServiceWorker Registered: {swRegistration ? "Yes" : "No"}</div>
      <div>Hostname: {window.location.hostname}</div> */}
    </div>
  );
};
