import { createBrowserRouter } from "react-router-dom";
import { Root } from "../components/root/Root";
import { clientRoutes } from "../screens/clients/ClientRoutes";
import { feedbackRoutes } from "../screens/feedbacks/feedbackRoutes";
import { orderRoutes } from "../screens/orders/orderRoutes";
import { sessionRoutes } from "../screens/sessions/sessionRoutes";
import { ErrorPage } from "../components/errors/ErrorPage";
import { leadsRoutes } from "../screens/leads/leadsRoutes";
import { treatmentRoutes } from "../screens/treatments/treatmentRoutes";
import { evaluationRoutes } from "../screens/evaluations/evaluationRoutes";
import { relativesRoutes } from "../screens/relatives/relativesRoutes";
import { tasksRoutes } from "../screens/tasks/tasksRoutes";
import { candidatesRoutes } from "../screens/candidates/candidatesRoutes";
import { dashboardRoutes } from "../screens/dashboards/dashboardsRoutes";
import { withAuthenticatedRoute } from "./withAuthenticatedRoute";
import { Login } from "../screens/login/Login";
import { customerSurveyRoutes } from "../screens/customer-surveys/customerSurveyRoutes";
import { bankTransactionsRoutes } from "../screens/bank-transactions/bankTransactionsRoutes";
import { partnersRoutes } from "../screens/partners/partnersRoutes";
import { templatesRoutes } from "../settings-screens/templates/templatesRoutes";
import { callsRoutes } from "../screens/calls/callsRoutes";
import { schedulesRoutes } from "../settings-screens/schedules/schedulesRoutes";
import { staffRoutes } from "../screens/staff/staffRoutes";
import { processesRoutes } from "../screens-processes/processesRoutes"; 
import { staffAgreementsRoutes } from "../settings-screens/staff-agreements/staffAgreementsRoutes";
import { neurofeedbackSessionRoutes } from "../screens/neurofeedback-zscore-sessions/neurofeedbackSessionRoutes";
import { protocolRoutes } from "../screens/protocols/protocolRoutes";
import { neurofeedbackFeedbackTypesRoutes } from "../settings-screens/neurofeedback-feedback-types/neurofeedbackFeedbackTypesRoutes";
import { Sandbox } from "../sandbox/Sandbox";
const routesToAuthenticate = [
    clientRoutes,
    feedbackRoutes,
    orderRoutes,
    sessionRoutes,
    leadsRoutes,
    treatmentRoutes,
    evaluationRoutes,
    relativesRoutes,
    tasksRoutes,
    candidatesRoutes,
    dashboardRoutes,
    customerSurveyRoutes,
    bankTransactionsRoutes,
    partnersRoutes,
    templatesRoutes,
    neurofeedbackFeedbackTypesRoutes,
    callsRoutes,
    schedulesRoutes,
    staffRoutes,
    processesRoutes,
    staffAgreementsRoutes,
    neurofeedbackSessionRoutes,
    protocolRoutes,
    [
        {
            path: "sandbox",
            element: <Sandbox />,
        },
    ],
];

//@ts-ignore
const authenticateRoutes = withAuthenticatedRoute(routesToAuthenticate);

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <ErrorPage />,
        children: authenticateRoutes,
    },

    {
        path: "/login",
        element: <Login />,
    },
]);
