import { DetailHeader } from "../../../../components/detailHeader/DetailHeader";
import { Box, IconButton, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { RecordContext } from "../../../../components/record/Record";
import { useContext } from "react";
import { formatDateWithTime } from "../../../../utils/dateUtils";
import { Avatar } from "../../../../components/fieldsViews/fields/Avatar";
import { renderFieldView } from "../../../../components/fieldsViews/renderFieldView";
import { findField } from "../../../../utils/findField";
import { NeurofeedbackChangeSession } from "./NeurofeedbackChangeSession";
import { OpenInNew } from "@mui/icons-material";

export const NeurofeedbackSessionHeader = () => {
    const nfbZScoreSession = useContext(RecordContext);

    const treatmentId = nfbZScoreSession?.item?.session?.treatment?.id;

    const previousSessionNumber = nfbZScoreSession?.item?.session_number - 1;
    const nextSessionNumber = nfbZScoreSession?.item?.session_number + 1;

    if (!nfbZScoreSession) return null;

    const session = nfbZScoreSession.item;
    const client = session?.session?.treatment?.client;
    const device = session?.device;
    const technician = session?.session?.technician?.user_id;
    const breathingField = findField(nfbZScoreSession.formatedFields, "diaphragmatic_breathing_status");
    const treatmentLink = `/treatments/${treatmentId}/detail`;
    return (
        <DetailHeader collection={nfbZScoreSession.collection}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
                    <Typography variant="h6" color="secondary">
                        Sessão de Neurofeedback Z-Score
                    </Typography>

                    <NeurofeedbackChangeSession
                        nfbZScoreSession={nfbZScoreSession}
                        previousSessionNumber={previousSessionNumber}
                        nextSessionNumber={nextSessionNumber}
                    />
                </Box>

                <Box sx={{ display: "flex", alignItems: "flex-start", gap: 8 }}>
                    <DataBox>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography
                                variant="body1"
                                color="primary"
                                component={Link}
                                sx={{ textDecoration: "none" }}
                                to={treatmentLink}
                            >
                                {client?.client_id}
                            </Typography>
                            <IconButton sx={{ ml: 2, p: 0 }} color="primary" component={Link} to={treatmentLink} target="_blank">
                                <OpenInNew />
                            </IconButton>
                        </Box>
                        <Typography variant="body1">{client?.user_id?.first_name}</Typography>
                    </DataBox>
                    <DataBox>
                        <Typography variant="body1" color="primary">
                            Sessão: {session?.session_number}
                        </Typography>
                        <Typography variant="body1">{formatDateWithTime(session?.session?.start_date)}</Typography>
                    </DataBox>
                    <DataBox>
                        <Typography variant="body1" color="primary">
                            Técnico
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <Avatar value={technician?.avatar} styles={{ width: 25, height: 25 }} />
                            <Typography variant="body1">
                                {technician?.first_name} {technician?.last_name}
                            </Typography>
                        </Box>
                    </DataBox>
                    <DataBox>
                        <Typography variant="body1" color="primary">
                            Equipamento
                        </Typography>
                        <Typography variant="body1">
                            {device?.name} - {device?.serial_number}
                        </Typography>
                    </DataBox>

                    <DataBox withoutGap>
                        <Typography variant="body1" color="primary">
                            Estado RD
                        </Typography>
                        {renderFieldView(breathingField, session, "group")}
                    </DataBox>
                    <DataBox>
                        <Typography variant="body1" color="primary">
                            Minutos Treino
                        </Typography>
                        <Typography variant="body1">{session?.minutes_of_trainning}</Typography>
                    </DataBox>
                </Box>
            </Box>
        </DetailHeader>
    );
};

type DataBoxProps = {
    children: React.ReactNode;
    withoutGap?: boolean;
};
const DataBox = ({ children, withoutGap = false }: DataBoxProps) => {
    return <Box sx={{ display: "flex", flexDirection: "column", gap: withoutGap ? 0 : 1 }}>{children}</Box>;
};
