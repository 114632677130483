import { DetailHeader } from "../../../components/detailHeader/DetailHeader";
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import { useContext } from "react";
import { RecordContext } from "../../../components/record/Record";
import { ClientSessionsSummary } from "../../clients/ClientSessionsSummary";
import { useClientSessionsSummary } from "../../../api/useClientSessionsSummary";
import { OpenInNew } from "@mui/icons-material";
import { NeurofeedbackDataButton } from "./NeurofeedbackDataButton";

export const SessionHeader = () => {
    const recordContext = useContext(RecordContext);
    const { data: sessions, status } = useClientSessionsSummary(recordContext?.item?.client?.id);

    if (!recordContext) return null;

    const { treatment, service } = recordContext.item;
    const client = treatment?.client;

    const treatmentLink = `/treatments/${treatment?.id}/detail`;

    return (
        <DetailHeader collection={recordContext.collection}>
            <Grid container alignItems="flex-start" wrap="nowrap" display="flex">
                <Grid minWidth={300}>
                    <Typography variant="h5">{service?.name}</Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                            variant="body1"
                            color="primary"
                            component={Link}
                            sx={{ textDecoration: "none" }}
                            to={treatmentLink}
                        >
                            {client?.client_id}
                        </Typography>
                        <IconButton sx={{ ml: 2, p: 0 }} color="primary" component={Link} to={treatmentLink} target="_blank">
                            <OpenInNew />
                        </IconButton>
                    </Box>
                    <Typography variant="body1">{client?.user_id?.first_name}</Typography>
                </Grid>
                <Grid>
                    {status === "loading" ? <CircularProgress /> : null}
                    {sessions && <ClientSessionsSummary sessions={sessions} options={{ flexDirection: "row" }} />}
                </Grid>
                <Grid>
                    <NeurofeedbackDataButton calendarSession={recordContext.item} />
                </Grid>
            </Grid>
        </DetailHeader>
    );
};
