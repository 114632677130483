import { Box, Button, IconButton } from "@mui/material";
import { renderFieldView } from "../../../renderFieldView";
import { DialogueState } from "./ProtocolGoalSelection";
import { Goal } from "./ProtocolGoalSelection";
import { FormatedField } from "../../../../../types/fields.types";
import { useDeleteRecord } from "../../../../../api/useDeleteRecord";
import { useStore } from "../../../../../store/store";
import { Delete } from "@mui/icons-material";

type GoalFieldProps = {
    goal: Goal;
    frequencyBand: FormatedField;
    goalType: FormatedField;
    setDialogueState: (state: DialogueState) => void;
};

export const GoalField = ({ goal, frequencyBand, goalType, setDialogueState }: GoalFieldProps) => {
    const deleteRecord = useDeleteRecord();
    const { notifications } = useStore();

    const handleDeleteGoal = async () => {
        const confirm = window.confirm("Tem certeza que deseja apagar este objetivo?");
        if (!confirm) return;
        try {
            await deleteRecord.mutateAsync({
                entity: "goals",
                id: goal.id,
            });
            notifications.setNotification({
                isOpen: true,
                severity: "success",
                message: "Objetivo apagado com sucesso",
            });
        } catch (error) {
            notifications.setNotification({
                isOpen: true,
                severity: "error",
                message: "Erro ao apagar objetivo",
            });
        }
    };

    return (
        <Box key={goal.id} sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Box>{renderFieldView(frequencyBand, { frequency_band: goal?.frequency_band }, "group")}</Box>
            <Box>{renderFieldView(goalType, { goal_type: goal?.goal_type }, "group")}</Box>
            <Box>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                        setDialogueState({ isOpen: true, type: "edit", goal: goal });
                    }}
                >
                    Editar
                </Button>
            </Box>
            <Box>
                <IconButton color="error" size="small" onClick={handleDeleteGoal}>
                    <Delete />
                </IconButton>
            </Box>
        </Box>
    );
};
