import { getRecords } from "../../api/useRecords";
import { Session } from "../../types/records.types";
import { NavigateFunction } from "react-router-dom";
import { Notifications } from "../../store/store.types";
import { createRecord } from "../../api/useCreateRecord";
import { NeurofeedbackData } from "../neurofeedback-zscore-sessions/types";

export const createNFBSession = async (session: Session, navigate: NavigateFunction, notifications: Notifications) => {
    const treatment = session?.treatment;
    try {
        const lastActiveProtocol = await getRecords("protocols", "*", {
            extraOptions: {
                filter: {
                    _and: [
                        {
                            treatment: {
                                _eq: treatment?.id,
                            },
                        },
                        {
                            status: {
                                _eq: "active",
                            },
                        },
                    ],
                },
                sort: "-date_created",
                pageSize: 1,
            },
        });

        if (lastActiveProtocol.items.length === 0) {
            notifications.setNotification({
                message: "Não foi possível criar a sessão de neurofeedback. Protocolo ainda não definido",
                severity: "error",
                isOpen: true,
            });
            return;
        }

        const allNFBSessionsDone = await getRecords("sessions", "*", {
            extraOptions: {
                filter: {
                    _and: [
                        {
                            service: {
                                _eq: 33,
                            },
                        },
                        {
                            status: {
                                _eq: "done",
                            },
                        },
                        {
                            treatment: {
                                _eq: treatment?.id,
                            },
                        },
                        {
                            id: {
                                _neq: session?.id,
                            },
                        },
                    ],
                },
            },
        });

        const nfbSessionNumber = allNFBSessionsDone.items.length;
        const lastActiveProtocolId = lastActiveProtocol?.items?.[0]?.id;
        
        // Prepare data object with required fields
        const sessionData: NeurofeedbackData = {
            session: session?.id,
            session_number: nfbSessionNumber + 1,
            protocol: lastActiveProtocolId,
        };
        
        const sessionToAskFeedbackFromClient = 10;
        if (nfbSessionNumber + 1 === sessionToAskFeedbackFromClient) {
            sessionData.notes = `<h4 style="color: red;">-------   É a sessão nº ${sessionToAskFeedbackFromClient}. Não esquecer de pedir feedback ao cliente.   -------</h4>`;
        }
        
        const response = await createRecord({
            entity: "neurofeedback_data",
            data: sessionData,
        });

        navigate(`/neurofeedback_data/${response.id}/detail`);
    } catch (error) {
        notifications.setNotification({
            message: "Erro ao criar sessão de neurorofeedback",
            severity: "error",
            isOpen: true,
        });
    }
};

export const createEEGSession = async (session: Session, navigate: NavigateFunction, notifications: Notifications) => {
    const treatmentId = session?.treatment.id;
    const sessionId = session?.id;

    try {
        const response = await createRecord({
            entity: "evaluations",
            data: {
                eeg_session: sessionId,
                treatment: treatmentId,
            },
        });

        navigate(`/evaluations/${response.id}/detail`);
    } catch (error) {
        notifications.setNotification({
            message: "Erro ao criar sessão de qEEG",
            severity: "error",
            isOpen: true,
        });
    }
};


export const getButtonText = (
    nfbSession: any,
    eegSession: any,
    calendarServiceId: any,
    nfbService: number,
    eegServices: Array<number>
) => {
    // Check if this is a neurofeedback service
    if (calendarServiceId === nfbService) {
        if (nfbSession) {
            return "Dados de neurofeedback sessão nº " + nfbSession?.session_number;
        } else {
            return "Criar dados neurofeedback";
        }
    }

    // Check if this is an EEG service
    if (eegServices.includes(calendarServiceId)) {
        if (eegSession) {
            return "Dados de qEEG";
        } else {
            return "Criar dados de qEEG";
        }
    }
};
